import React, {useState, useEffect} from 'react';
import classes from './CustomNavStyles.module.css';
import {StyledEngineProvider} from '@mui/material/styles';
import {Box} from '@mui/material';
import SideBarComponent from './SideBarComponent/SideBarComponent';
import PowerBIReport from '../../pages/PowerBiReport/PowerBiReport';
import {useSelector, useDispatch} from 'react-redux';
import {userReportsActivity} from '../Utilities/apis/loginActivity';
import getReportAccess from '../Utilities/apis/getReportAccess';
import {
	addSelectedReport,
	addEmbedToken,
	addReportEmbedUrls,
} from '../../redux/userReducer';
import {
	checkvalidEmbedToken,
	reportEmbedTokenRefresh,
} from '../Utilities/checkValidToken';
import PowerBiError from '../../pages/PowerBiReport/PowerBiError';

const CustomNav = ({reportsArray}) => {
	const embedToken = useSelector((state) => state.PowerBi.reportEmbedToken);
	const embedUrls = useSelector((state) => state.PowerBi.reportEmbedUrls);
	const defaultReport = useSelector((state) => state.PowerBi.defaultReport);
	const selectedReport = useSelector((state) => state.PowerBi.selectedReport);
	const user = useSelector((state) => state.PowerBi.user);
	const [embedReportError, setEmbedReportError] = useState(false);
	const [embedUrl, setembedUrl] = useState(null);
	const dispatch = useDispatch();

	const handleEmbedError = () => {
		setEmbedReportError(true);
	};

	const handleReports = (report) => {
		setembedUrl(embedUrls[report.PBIREPORTID]);
		userReportsActivity([user?.ID, report.reportId]);
	};

	const refreshEmbedToken = async () => {
		getReportAccess(
			defaultReport?.PBIWORKSPACEID,
			user?.PBI_ROLE,
			user?.EMAILID,
			user?.ID
		).then((result) => {
			if (result.length > 1) {
				dispatch(addReportEmbedUrls(result[1]));
				dispatch(addEmbedToken(result[0].token));
				reportEmbedTokenRefresh(refreshEmbedToken, result[0].token);
				setEmbedReportError(false);
			} else {
				setEmbedReportError(true);
			}
		});
	};

	useEffect(() => {
		if (Object.keys(selectedReport).length === 0) {
			dispatch(addSelectedReport(defaultReport));
		}
	}, [defaultReport, dispatch, selectedReport]);

	useEffect(() => {
		const intialembedTokenSetting = async () => {
			await getReportAccess(
				defaultReport?.PBIWORKSPACEID,
				user?.PBI_ROLE,
				user?.EMAILID,
				user?.ID
			).then((result) => {
				if (result.length > 1) {
					dispatch(addReportEmbedUrls(result[1]));
					dispatch(addEmbedToken(result[0].token));
					reportEmbedTokenRefresh(refreshEmbedToken, result[0].token);
					setEmbedReportError(false);
				} else {
					setEmbedReportError(true);
				}
			});
		};
		if (Object.keys(defaultReport).length !== 0) {
			intialembedTokenSetting();
		}
	}, [defaultReport, dispatch, user?.EMAILID, user?.ID, user?.PBI_ROLE]);

	useEffect(() => {
		if (
			Object.keys(selectedReport).length !== 0 &&
			checkvalidEmbedToken(embedToken)
		) {
			setembedUrl(embedUrls[selectedReport.PBIREPORTID]);
		}
	}, [dispatch, embedToken, embedUrls, user.ID]);

	return (
		<StyledEngineProvider injectFirst>
			<Box className={classes.sidebarContainer}>
				<Box className={classes.sidebar}>
					<Box style={{maxHeight: '100%'}}>
						<SideBarComponent
							reports={reportsArray}
							handleReports={handleReports}
						/>
					</Box>
				</Box>
			</Box>

			<Box width={'100%'}>
				{!embedReportError ? (
					checkvalidEmbedToken(embedToken) &&
					Object.keys(selectedReport).length !== 0 && (
						<PowerBIReport
							selectedReport={selectedReport}
							embedUrl={embedUrl}
							embedToken={embedToken}
							handleEmbedError={handleEmbedError}
						/>
					)
				) : (
					<PowerBiError refreshEmbedToken={refreshEmbedToken} />
				)}
			</Box>
		</StyledEngineProvider>
	);
};

export default CustomNav;
