// Function to Get All Users Data to Admin Screen table
import axios from 'axios';
import config from '../../config';

const getAudiencesData = async () => {
	const token = localStorage.getItem('googleIdToken');

	try {
		const result = await axios.get(`${config.apiURL}/getAudiences`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		return Array.isArray(result?.data) ? result?.data : [];
	} catch (err) {
		return [];
	}
};

export default getAudiencesData;
