import axios from 'axios';
import config from '../../../config';
const getReportAccess = async (groupId, roleName, email, userId) => {
	const token = localStorage.getItem('googleIdToken');
	try {
		const result = await axios.get(
			`${config.apiURL}/getReportAccess?groupId=${groupId}&roleName=${roleName}&email=${email}`,
			{
				headers: {
					userId,
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return Array.isArray(result?.data) ? result?.data : [];
	} catch (error) {
		return [];
	}
};

export default getReportAccess;
