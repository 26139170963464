import {Grid, Button, Typography, Autocomplete, TextField} from '@mui/material';
import classes from './TwoWayCommunicationForm.module.css';
import {useEffect, useState} from 'react';
import {StyledEngineProvider} from '@mui/material/styles';
import {useOutletContext} from 'react-router-dom';
import twoWayCommunicationApi from '../Utilities/apis/twoWayCommunicationApi';
import CloseIcon from '@mui/icons-material/Close';
import {useSelector} from 'react-redux';
import CustomAlert from '../../components/Alerts/CustomAlert';
import CircularProgress from '@mui/material/CircularProgress';

const Form = (props) => {
	const {handleClose, form} = props;
	const TwoWayFormData = useSelector((state) => state.Form.TwoWayFormData);
	const [finalProjectionsData, setFinalProjectionsData] = useState([]);
	const [resultObject, setresultObject] = useState([]);
	//values for alerts initialization
	const [alert, setAlert] = useState(false);
	const [alertmessage, setAlertMessage] = useState(null);
	const [success, setSuccess] = useState(false);
	const [emailsendingStatus, setemailsendingStatus] = useState(false);
	//to set the values for the dropdown ,intializing local useState's
	const [CustomerGroup, setCustomerGroup] = useState('');
	const [BuUser, setBuUser] = useState('');
	const [DpUser, setDpUser] = useState('');
	const [CpUser, setCpUser] = useState('');
	const [netAddCP, setNetAddCP] = useState(null);
	const [netAddDP, setNetAddDP] = useState(null);
	const [netAddDossier, setNetAddDossier] = useState(null);
	//setting Options of the Dropdown for Customer Group AutoComplete
	const uniqueCustomerGroupName = [];
	const uniqueCustomerGroupOptions = TwoWayFormData?.filter((item) => {
		if (!uniqueCustomerGroupName.includes(item?.CUSTOMER_GROUP)) {
			uniqueCustomerGroupName.push(item?.CUSTOMER_GROUP);
			return true;
		}
		return false;
	});
	//intializing useState for Options for CP,DP and BU's DropDowns
	const [uniqueBUOptions, setUniqueBUOptions] = useState([]);
	const [uniqueCPOptions, setUniqueCPOptions] = useState([]);
	const [uniqueDPOptions, setUniqueDPOptions] = useState([]);
	const googleIdToken = useOutletContext(); // <-- access context value
	const [formData, setFormData] = useState({});

	//this function helps to return the unique options and sets the first value of that array which is
	//not null to the option
	const getUniqueArrayObject = (data, columnName, setState) => {
		const uniqueColumns = [];
		const uniqueList = data?.filter((item) => {
			if (item[columnName] && !uniqueColumns.includes(item[columnName])) {
				uniqueColumns.push(item[columnName]);
				return true;
			}
			return false;
		});
		if (uniqueList.length === 0) {
			setState('');
		} else {
			setState(uniqueList?.[0]);
		}
		return uniqueList;
	};

	//this function will execute whenever there is a change in the other options , other
	// than the customer group , and set that object to the all the other dropdowns
	const FilterOptionsOnChange = (value, columnName) => {
		if (value) {
			let r = finalProjectionsData?.filter((obj) => {
				return obj[columnName] === value[columnName];
			});
			if (r.length === 0) {
				setBuUser('');
				setCpUser('');
				setDpUser('');
			} else {
				setBuUser(r[0]);
				setCpUser(r[0]);
				setDpUser(r[0]);
			}
			setresultObject(r);
		} else {
			setresultObject([]);
		}
	};

	// function to handle the text whnever there is change in the remarks
	const handleText = (event) => {
		setFormData(() => ({
			...formData,
			[event.target.name]: event.target.value,
		}));
	};
	// function to handle netaddcorrection
	const handleNetAddCorrection = (event) => {
		setFormData(() => ({
			...formData,
			[event.target.name]: event.target.value,
		}));
	};
	const handleAlertClose = () => {
		setAlert(false);
	};

	const CalculateNetAdd = (a, b) => {
		return Math.round(a - b);
	};

	//function to execute whenever there is change in the customer group
	const handleCustomerGroup = (e, value) => {
		if (value) {
			setCustomerGroup(() => {
				return value;
			});
			let res = TwoWayFormData?.filter(
				(rep) => rep['CUSTOMER_GROUP'] === value?.['CUSTOMER_GROUP']
			);
			setFinalProjectionsData(res);
			setresultObject(res);
			setUniqueCPOptions(getUniqueArrayObject(res, 'CP', setCpUser));
			setUniqueDPOptions(getUniqueArrayObject(res, 'DP', setDpUser));
			setUniqueBUOptions(getUniqueArrayObject(res, 'BU', setBuUser));
		} else {
			setCustomerGroup(() => {
				return '';
			});
			setFinalProjectionsData([]);
			setresultObject([]);
			setUniqueCPOptions(getUniqueArrayObject([], 'CP', setCpUser));
			setUniqueDPOptions(getUniqueArrayObject([], 'DP', setDpUser));
			setUniqueBUOptions(getUniqueArrayObject([], 'BU', setBuUser));
		}
	};

	const handleDropDownCP = (e, value) => {
		if (value) {
			setCpUser(() => {
				return value;
			});
			FilterOptionsOnChange(value, 'CP');
		} else {
			setCpUser(() => {
				return '';
			});
			FilterOptionsOnChange('', 'CP');
		}
	};
	const handleDropDownDP = (e, value) => {
		if (value) {
			setDpUser(() => {
				return value;
			});
			FilterOptionsOnChange(value, 'DP');
		} else {
			setDpUser(() => {
				return '';
			});
			FilterOptionsOnChange('', 'DP');
		}
	};
	const handleDropDownBU = (e, value) => {
		if (value) {
			setBuUser(() => {
				return value;
			});
			FilterOptionsOnChange(value, 'BU');
		} else {
			setBuUser(() => {
				return '';
			});
			FilterOptionsOnChange('', 'BU');
		}
	};
	// Function to execute ONSubmit
	const onSubmit = async () => {
		// Code To check Empty and Null Values
		const isEmpty = Object.values(formData).some(
			(x) => x === '' || x === null || x === undefined || x === 'undefined'
		);
		if (resultObject.length !== 0 && !isEmpty) {
			if (resultObject.length === 1) {
				setemailsendingStatus(true);
				twoWayCommunicationApi({googleIdToken, formData, resultObject}).then(
					(res) => {
						if (res.status === 200) {
							setAlertMessage(res.data.message);
							setAlert(true);
							setSuccess(true);
							setFormData({});
							handleCustomerGroup('');
						} else {
							setAlertMessage(res.response.data.message);
							setAlert(true);
							setSuccess(false);
						}
						setemailsendingStatus(false);
						setTimeout(() => {
							handleClose();
						}, 2000);
					}
				);
			} else {
				setemailsendingStatus(true);
				const r = resultObject?.filter(
					(obj) =>
						((obj.BU === BuUser.BU || obj.BU === BuUser) &&
							(obj.CP === CpUser.CP || obj.CP === CpUser) &&
							(obj.DP === DpUser.DP || obj.DP === DpUser) &&
							obj.CUSTOMER_GROUP === CustomerGroup.CUSTOMER_GROUP) ||
						obj.CUSTOMER_GROUP === CustomerGroup
				);
				twoWayCommunicationApi({googleIdToken, formData, resultObject: r}).then(
					(res) => {
						if (res.status === 200) {
							setAlertMessage(res.data.message);
							setAlert(true);
							setSuccess(true);
							setFormData({});
							handleCustomerGroup('');
						} else {
							setAlertMessage(res.response.data.message);
							setAlert(true);
							setSuccess(false);
						}
						setemailsendingStatus(false);
						setTimeout(() => {
							handleClose();
						}, 2000);
					}
				);
			}
		}
	};

	//Use Effect to set the intial form Data
	useEffect(() => {
		// setting Local state for form Data
		if (Object.keys(form).length !== 0) {
			setFormData({
				Email_Id: form.EMAILID ? form.EMAILID : '',
				Pbi_Role: form.PBI_ROLE ? form.PBI_ROLE : '',
				NETADD_CP: netAddCP,
				NETADD_DP: netAddDP,
				NETADD_DOSSIER: netAddDossier,
				NETADD_CORRECTION: formData?.NETADD_CORRECTION,
				remarks: formData?.remarks,
			});
		}
	}, [
		form,
		formData?.NETADD_CORRECTION,
		formData?.remarks,
		netAddCP,
		netAddDP,
		netAddDossier,
	]);
	useEffect(() => {
		if (resultObject.length === 1) {
			setNetAddCP(
				CalculateNetAdd(
					Number(resultObject?.[0]?.CP_PROJECTIONS_CURR_MONTH),
					Number(resultObject?.[0]?.SOW_IND_PREV_MONTH)
				)
			);
			setNetAddDP(
				CalculateNetAdd(
					Number(resultObject?.[0]?.DP_PROJECTIONS_CURR_MONTH),
					Number(resultObject?.[0]?.SOW_IND_PREV_MONTH)
				)
			);
			setNetAddDossier(
				CalculateNetAdd(
					Number(resultObject?.[0]?.SOW_IND_CURR_MONTH),
					Number(resultObject?.[0]?.SOW_IND_PREV_MONTH)
				)
			);
		} else {
			if (resultObject.length > 1) {
				const r = resultObject?.filter(
					(obj) =>
						((obj.BU === BuUser.BU || obj.BU === BuUser) &&
							(obj.CP === CpUser.CP || obj.CP === CpUser) &&
							(obj.DP === DpUser.DP || obj.DP === DpUser) &&
							obj.CUSTOMER_GROUP === CustomerGroup.CUSTOMER_GROUP) ||
						obj.CUSTOMER_GROUP === CustomerGroup
				);
				setNetAddCP(
					CalculateNetAdd(
						Number(r?.[0]?.CP_PROJECTIONS_CURR_MONTH),
						Number(r?.[0]?.SOW_IND_PREV_MONTH)
					)
				);
				setNetAddDP(
					CalculateNetAdd(
						Number(r?.[0]?.DP_PROJECTIONS_CURR_MONTH),
						Number(r?.[0]?.SOW_IND_PREV_MONTH)
					)
				);
				setNetAddDossier(
					CalculateNetAdd(
						Number(r?.[0]?.SOW_IND_CURR_MONTH),
						Number(r?.[0]?.SOW_IND_PREV_MONTH)
					)
				);
			}
			if (resultObject.length === 0) {
				setNetAddCP(null);
				setNetAddDP(null);
				setNetAddDossier(null);
			}
		}
	}, [
		BuUser,
		CpUser,
		CustomerGroup,
		DpUser,
		finalProjectionsData,
		resultObject,
	]);
	return (
		<StyledEngineProvider injectFirst>
			<Grid
				container
				item
				xs={6}
				md={6}
				xl={6}
				className={classes.formContainer}
			>
				<CustomAlert
					alert={alert}
					message={alertmessage}
					handleClose={handleAlertClose}
					success={success}
				/>
				{resultObject.length === 0 ? (
					<Grid style={{color: 'red'}}>**All fields are required**</Grid>
				) : (
					''
				)}
				<Grid
					container
					className={classes.heading}
				>
					<Grid
						className={classes.left}
						item
						xs={6}
						md={6}
						xl={6}
					>
						<Typography style={{color: '#312E2D', fontWeight: 'bold'}}>
							Projections
						</Typography>
					</Grid>
					<Grid
						className={classes.right}
						item
						xs={6}
						md={6}
						xl={6}
					>
						<CloseIcon
							className={classes.closeButton}
							onClick={handleClose}
						/>
					</Grid>
				</Grid>
				<Grid
					xs={6}
					md={6}
					xl={6}
					item
					className={classes.textField}
				>
					<Autocomplete
						renderOption={(props, option) => (
							<Typography
								{...props}
								style={{fontSize: '12px'}}
							>
								{option?.CUSTOMER_GROUP || ''}
							</Typography>
						)}
						options={uniqueCustomerGroupOptions}
						key={uniqueCustomerGroupOptions}
						id='CustomerGroup'
						autoHighlight
						value={CustomerGroup}
						isOptionEqualToValue={(option, value) =>
							option?.CUSTOMER_GROUP === value?.CUSTOMER_GROUP || value === ''
						}
						getOptionLabel={(option) =>
							option?.CUSTOMER_GROUP ? option?.CUSTOMER_GROUP : ''
						}
						className={classes.autoComplete}
						renderInput={(params) => (
							<TextField
								{...params}
								label='Customer Group'
								variant='outlined'
								size='small'
							/>
						)}
						onChange={(e, value) => handleCustomerGroup(e, value)}
					/>
				</Grid>
				<Grid
					xs={6}
					md={6}
					xl={6}
					item
					className={classes.textField}
				>
					<Autocomplete
						renderOption={(props, option) => (
							<Typography
								{...props}
								style={{fontSize: '12px'}}
							>
								{option?.CP || ''}
							</Typography>
						)}
						options={uniqueCPOptions}
						key={uniqueCPOptions}
						disabled={uniqueCPOptions.length <= 1 ? true : false}
						isOptionEqualToValue={(option, value) =>
							option['CP'] === value['CP'] || value === ''
						}
						getOptionLabel={(option) => option?.CP || ''}
						id='ClientPartner'
						name='CP'
						autoHighlight
						value={CpUser}
						className={classes.autoComplete}
						renderInput={(params) => (
							<TextField
								{...params}
								label='Client partner'
								variant='outlined'
								size='small'
							/>
						)}
						onChange={(e, value) => handleDropDownCP(e, value)}
					/>
				</Grid>
				<Grid
					xs={6}
					md={6}
					xl={6}
					item
					className={classes.textField}
				>
					<Autocomplete
						renderOption={(props, option) => (
							<Typography
								{...props}
								style={{fontSize: '12px'}}
							>
								{option?.BU}
							</Typography>
						)}
						disabled={uniqueBUOptions.length <= 1 ? true : false}
						key={uniqueBUOptions}
						options={uniqueBUOptions}
						isOptionEqualToValue={(option, value) =>
							option?.BU === value?.BU || value === ''
						}
						getOptionLabel={(option) => (option.BU ? option.BU : '')}
						id='BU'
						autoHighlight
						value={BuUser}
						className={classes.autoComplete}
						renderInput={(params) => (
							<TextField
								{...params}
								label='BU'
								variant='outlined'
								size='small'
							/>
						)}
						onChange={(e, value) => handleDropDownBU(e, value)}
					/>
				</Grid>
				<Grid
					xs={6}
					md={6}
					xl={6}
					item
					className={classes.textField}
				>
					<Autocomplete
						renderOption={(props, option) => (
							<Typography
								{...props}
								style={{fontSize: '12px'}}
							>
								{option?.DP}
							</Typography>
						)}
						options={uniqueDPOptions}
						disabled={uniqueDPOptions.length <= 1 ? true : false}
						key={uniqueDPOptions}
						isOptionEqualToValue={(option, value) =>
							option?.DP === value?.DP || value === ''
						}
						getOptionLabel={(option) => (option?.DP ? option?.DP : '')}
						id='DeliveryPartner'
						autoHighlight
						value={DpUser}
						className={classes.autoComplete}
						renderInput={(params) => (
							<TextField
								{...params}
								label='Delivery partner'
								variant='outlined'
								size='small'
							/>
						)}
						onChange={(e, value) => handleDropDownDP(e, value)}
					/>
				</Grid>
				<Grid
					container
					className={classes.textField}
				>
					<Grid
						item
						md={2}
						xl={2}
						xs={2}
						className={classes.numField}
					>
						<TextField
							disabled
							variant='outlined'
							InputLabelProps={{shrink: true, variant: 'outlined'}}
							label='NetAdd CP'
							type='number'
							key={netAddCP}
							value={netAddCP || 0}
						/>
					</Grid>
					<Grid
						item
						md={2}
						xl={2}
						xs={2}
						className={classes.numField}
					>
						<TextField
							disabled
							variant='outlined'
							InputLabelProps={{shrink: true, variant: 'outlined'}}
							label='NetAdd DP'
							type='number'
							key={netAddDP}
							value={netAddDP || 0}
						/>
					</Grid>
					<Grid
						item
						md={2}
						xl={2}
						xs={2}
						className={classes.numField}
					>
						<TextField
							disabled
							variant='outlined'
							InputLabelProps={{shrink: true, variant: 'outlined'}}
							label='NetAdd Dossier'
							type='number'
							key={netAddDossier}
							value={netAddDossier || 0}
						/>
					</Grid>
					<Grid
						item
						md={2}
						xl={2}
						xs={2}
						className={classes.numField}
					>
						<TextField
							variant='outlined'
							InputLabelProps={{shrink: true, variant: 'outlined'}}
							label='NetAdd Correction'
							type='number'
							name='NETADD_CORRECTION'
							key={formData?.NETADD_CORRECTION}
							value={formData?.NETADD_CORRECTION}
							onChange={(e, value) => handleNetAddCorrection(e, value)}
							autoFocus
							sx={{
								'& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button':
									{
										'-webkit-appearance': 'none',
										margin: 0,
									},
							}}
						/>
					</Grid>
				</Grid>
				<Grid
					item
					xs={12}
					md={12}
					xl={12}
					className={classes.dataField}
				>
					<TextField
						id='outlined-multiline-flexible'
						label='Remarks'
						name='remarks'
						autoFocus
						onChange={(e, value) => handleText(e, value)}
						multiline
						fullWidth
						maxRows={4}
						minRows={4}
						value={formData?.remarks || ''}
						className={classes.autoComplete}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					md={12}
					xl={12}
					className={classes.buttonContainer}
				>
					{emailsendingStatus ? (
						<Button className={classes.submitButton}>
							<CircularProgress
								className={classes.loader}
								style={{width: '15px', height: '15px', color: 'green'}}
							/>
						</Button>
					) : (
						<Button
							className={classes.submitButton}
							onClick={onSubmit}
						>
							Submit
						</Button>
					)}
				</Grid>
			</Grid>
		</StyledEngineProvider>
	);
};

export default Form;
