import {useState, useEffect} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import checkValidToken, {checkvalidEmbedToken} from './checkValidToken';
import Login from '../../pages/Login/Login';
import refreshToken from './apis/refreshToken';
import {useNavigate} from 'react-router-dom';
import removeTokens from './handleLogout';
import {resetUser} from '../../redux/userReducer';

const PrivateRoutes = () => {
	const [refreshTimeout, setrefreshTimeout] = useState(false);
	const dispatch = useDispatch();
	const [googleToken, setGoogleToken] = useState(() =>
		localStorage.getItem('googleIdToken')
	);
	const location = useLocation();
	const navigate = useNavigate();
	const user = useSelector((state) => state.PowerBi.user);
	const userlogin = Object.keys(user).length !== 0;

	var timeOut = () => {
		clearTimeout(() => {
			setrefreshTimeout(true);
		});
		setTimeout(() => {
			setrefreshTimeout(true);
		}, 14400000);
	};
	const refreshgoogleToken = async () => {
		if (localStorage.getItem('refreshToken')) {
			await refreshToken().then((result) => {
				if (!result) {
					localStorage.removeItem('googleIdToken');
					setrefreshTimeout(true);
					return;
				} else {
					localStorage.removeItem('googleIdToken');
					localStorage.setItem('googleIdToken', result);
					setGoogleToken(result);
					checkValidToken(
						refreshgoogleToken,
						localStorage.getItem('googleIdToken')
					);
				}
			});
		} else {
			setrefreshTimeout(true);
			return;
		}
	};
	useEffect(() => {
		if (refreshTimeout) {
			removeTokens();
			dispatch(resetUser({}));
			navigate('/', {state: {location: location}});
		}
	}, [dispatch, location, navigate, refreshTimeout]);

	useEffect(() => {
		timeOut();
	}, []);

	useEffect(() => {
		const intialgoogleTokenSetting = async () => {
			if (localStorage.getItem('refreshToken')) {
				await refreshToken().then((result) => {
					if (!result) {
						localStorage.removeItem('googleIdToken');
						setrefreshTimeout(true);
						return;
					} else {
						localStorage.removeItem('googleIdToken');
						localStorage.setItem('googleIdToken', result);
						setGoogleToken(result);
						checkValidToken(
							refreshgoogleToken,
							localStorage.getItem('googleIdToken')
						);
					}
				});
			} else {
				setrefreshTimeout(true);
				return;
			}
		};
		if (
			!checkvalidEmbedToken(localStorage.getItem('googleIdToken')) &&
			localStorage.getItem('refreshToken') &&
			Object.keys(user).length !== 0
		) {
			intialgoogleTokenSetting();
		} else if (
			localStorage.getItem('refreshToken') &&
			Object.keys(user).length !== 0
		) {
			checkValidToken(
				refreshgoogleToken,
				localStorage.getItem('googleIdToken')
			);
			setGoogleToken(localStorage.getItem('googleIdToken'));
		}
	}, [user]);

	let auth = {isloggedIn: userlogin && localStorage.getItem('refreshToken')};

	return auth.isloggedIn ? (
		<Outlet context={googleToken} />
	) : (
		<Login location={location.pathname} />
	);
};

export default PrivateRoutes;
