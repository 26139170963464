import React, {useState} from 'react';
import './Tabs.css'; // Import CSS file for styling
import HomeIcon from '@mui/icons-material/Home';
import {useNavigate} from 'react-router-dom';
import {Tooltip} from '@mui/material';

const Tabs = ({tabs}) => {
	const [activeTab, setActiveTab] = useState(0);
	const Navigate = useNavigate();

	const handleTabClick = (index) => {
		setActiveTab(index);
	};

	return (
		<div className='tabs-container'>
			<div className='tabs'>
				<span
					style={{display: 'flex', alignItems: 'center', marginRight: '2rem'}}
				>
					<Tooltip title={'Click here for ReportsPage'}>
						<HomeIcon
							className='text'
							onClick={() => Navigate('/reports')}
							style={{cursor: 'pointer'}}
						/>
					</Tooltip>
				</span>
				{tabs.map((tab, index) => (
					<div
						key={index}
						className={index === activeTab ? 'tab active' : 'tab'}
						onClick={() => handleTabClick(index)}
					>
						{tab.title}
					</div>
				))}
			</div>
			<div className='tab-content'>{tabs[activeTab].content}</div>
		</div>
	);
};

export default Tabs;
