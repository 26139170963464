import {Box, Grid, Typography} from '@mui/material';
import classes from './UserPopup.module.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import {useDispatch, useSelector} from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import {useState} from 'react';
import {StyledEngineProvider} from '@mui/material/styles';
import CustomAlert from '../../components/Alerts/CustomAlert';

const UsersPopUp = (props) => {
	const dispatch = useDispatch();
	const {handleClose, audienceName, audienceId} = props;
	const [submitOpen, setSubmitOpen] = useState(false);
	const [alert, setAlert] = useState(false);
	const [alertmessage, setAlertMessage] = useState(null);
	const [success, setSuccess] = useState(false);
	const userAudience = useSelector(
		(state) => state.AdminPageData.userDetailsByAudience
	);
	const handleSubmitOpen = () => {
		setSubmitOpen(!submitOpen);
	};

	const handleAlert = (res) => {
		if (res.status === 200) {
			setAlertMessage(res.data.message);
			setAlert(true);
			setSuccess(true);
		} else {
			setAlertMessage(res.response.data.message);
			setAlert(true);
			setSuccess(false);
		}
	};

	const handleAlertClose = () => {
		setAlert(false);
	};
	return (
		<StyledEngineProvider injectFirst>
			<Grid
				item
				xl={6}
				xs={6}
				md={6}
				className={classes.reportModal}
			>
				<Grid
					container
					className={classes.firstline}
				>
					<Grid
						item
						xs={6}
						md={6}
						xl={6}
						className={classes.left}
					>
						<Typography style={{fontSize: '14px', fontWeight: 'bold'}}>
							{audienceName}Users
						</Typography>
						<CustomAlert
							alert={alert}
							message={alertmessage}
							handleClose={handleAlertClose}
							success={success}
						/>
					</Grid>
					<Grid
						item
						xs={6}
						md={6}
						xl={6}
						className={classes.right}
					>
						<CloseIcon
							className={classes.closeIcon}
							onClick={handleClose}
						/>
					</Grid>
				</Grid>
				{userAudience && userAudience?.length > 0 ? (
					<TableContainer
						component={Paper}
						className={classes.table}
						sx={{
							'&::-webkit-scrollbar': {
								width: 6,
							},
							'&::-webkit-scrollbar-track': {
								backgroundColor: '#F4F4F4',
							},
							'&::-webkit-scrollbar-thumb': {
								backgroundColor: '#ccc',
								borderRadius: 2,
							},
						}}
						style={{
							boxShadow: 'none',
							minWidth: 650,
							minHeight: (window.screen.height * 40) / 100,
							maxHeight: (window.screen.height * 50) / 100,
						}}
					>
						<Table aria-label='simple table'>
							<TableHead>
								<TableRow
									sx={{
										backgroundColor: 'rgb(37, 200, 241)',
										fontSize: '14px',
										color: '#312E2D',
										fontWeight: 'bold',
									}}
								>
									<TableCell
										sx={{fontWeight: 'bold', padding: '2.5px'}}
										align='center'
									>
										S-No
									</TableCell>
									<TableCell sx={{fontWeight: 'bold', padding: '2.5px'}}>
										EMAILID
									</TableCell>
									<TableCell
										sx={{fontWeight: 'bold', padding: '2.5px'}}
										align='left'
									>
										Name
									</TableCell>
									<TableCell
										sx={{fontWeight: 'bold', padding: '2.5px'}}
										align='left'
									>
										PBI_ROLE
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{userAudience?.map((row, id) => (
									<TableRow
										key={id}
										sx={{
											'&:last-child td, &:last-child th': {border: 0},
											'&:nth-of-type(even)': {
												backgroundColor: 'white',
											},
											'&:nth-of-type(odd)': {
												backgroundColor: 'rgb(200, 237, 246)',
											},
										}}
									>
										<TableCell
											align='center'
											sx={{padding: '2.5px'}}
										>
											{id + 1}
										</TableCell>
										<TableCell
											component='th'
											scope='row'
											align='left'
											sx={{padding: '2.5px'}}
										>
											{row?.EMAILID}
										</TableCell>
										<TableCell
											align='left'
											sx={{padding: '2.5px'}}
										>
											{row?.FIRSTNAME} {row?.LASTNAME}
										</TableCell>
										<TableCell
											align='left'
											sx={{padding: '2.5px'}}
										>
											{row?.PBI_ROLE}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				) : (
					<Box
						style={{
							boxShadow: 'none',
							minWidth: 650,
							minHeight: (window.screen.height * 40) / 100,
							maxHeight: (window.screen.height * 50) / 100,
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						No Users
					</Box>
				)}
			</Grid>
		</StyledEngineProvider>
	);
};

export default UsersPopUp;
