import axios from 'axios';
import config from '../../../config';
const loginActivity = async (props) => {
	const token = localStorage.getItem('googleIdToken');
	const [userId, ActivityId] = props;
	try {
		const res = await axios.post(`${config.apiURL}/addUserActivity`, {
			userId,
			ActivityId,
			Authorization: `Bearer ${token}`,
		});
	} catch (err) {
		return err;
	}
};

const userReportsActivity = async (props) => {
	const token = localStorage.getItem('googleIdToken');
	const [userId, reportId] = props;
	try {
		const res = await axios.post(`${config.apiURL}/addUserReportsActivity`, {
			userId,
			reportId,
			Authorization: `Bearer ${token}`,
		});
	} catch (err) {
		return err;
	}
};
export default loginActivity;
export {userReportsActivity};
