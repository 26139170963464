// Function to Get All Users Data to Admin Screen table
import axios from 'axios';
import config from '../../../../config';

const getRefreshDate = async (props) => {
	const [reportId, groupId] = props;
	const token = localStorage.getItem('googleIdToken');
	try {
		const res = await axios.get(
			`${config.apiURL}/refreshDate?groupId=${groupId}&reportId=${reportId}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return res.data;
	} catch (err) {
		return [];
	}
};

export {getRefreshDate};
