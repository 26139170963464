import React from 'react';
import Draggable from 'react-draggable';
import {Modal, Box} from '@mui/material';

function DraggablePaperComponent(props) {
	return (
		<Draggable handle='#draggable-modal-title'>
			<Box {...props} />
		</Draggable>
	);
}

export default function DraggableModal(props) {
	const {children, open, sx} = props;

	return (
		<Modal
			open={open}
			aria-labelledby='modal-modal-title'
			aria-describedby='modal-modal-description'
			sx={{...sx}}
		>
			<>
				<DraggablePaperComponent
					id='draggable-modal-title'
					sx={{...sx}}
				>
					{children}
				</DraggablePaperComponent>
			</>
		</Modal>
	);
}
