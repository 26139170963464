import classes from './PowerBiError.module.css';
import {Box, Typography} from '@mui/material';
import {useEffect, useState} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {useSelector} from 'react-redux';
import {checkvalidEmbedToken} from '../../components/Utilities/checkValidToken';
import {ReactComponent as ErrorLogo} from '../../components/Assets/Error_symbol.svg';

const PowerBiError = ({refreshEmbedToken}) => {
	const embedToken = useSelector((state) => state.PowerBi.reportEmbedToken);
	const [result, setResult] = useState(false);
	useEffect(() => {
		refreshEmbedToken().then(() => {
			if (checkvalidEmbedToken(embedToken)) {
				setResult(false);
			} else {
				setResult(true);
			}
		});
	}, []);
	return (
		<Box className={classes.powerBIError}>
			{!result ? (
				<CircularProgress
					style={{
						color: '#f7901d',
						position: 'fixed',
						top: '50%',
						left: '50%',
					}}
				/>
			) : (
				<Box
					className={classes.errorBox}
					style={{top: '40%', left: '50%', position: 'fixed'}}
				>
					<ErrorLogo />
					<span className={classes.oopsText}>Oops!</span>
					<Typography className={classes.errorMessage}>
						Failed to Generate Embed Token Kindly Refresh the Page
					</Typography>
					<Box
						className={classes.refreshButton}
						onClick={refreshEmbedToken}
					>
						Refresh
					</Box>
				</Box>
			)}
		</Box>
	);
};

export default PowerBiError;
