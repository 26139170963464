import {StyledEngineProvider} from '@mui/material/styles';
import styles from './PageNotFound.module.css';
import {ReactComponent as Onedataicon} from '../../components/Assets/OnedataBlackicon.svg';
import {ReactComponent as TigerLogo} from '../../components/Assets/TA_logo_Black.svg';
import {Divider, Grid, Button, Typography} from '@mui/material';

const PageNotFound = ({location}) => {
	return (
		<StyledEngineProvider injectFirst>
			<div className={styles.app}>
				<>
					<Typography className={styles.message}>
						<span style={{fontSize: '20px'}}>
							OneData has moved to a New Domain
						</span>
						<span style={{fontSize: '12px'}}>Please bookmark the New URL</span>
						<span
							style={{fontSize: '16px', marginTop: '10px', fontStyle: 'italic'}}
						>
							New URL:
							<a
								href='https://onedata.tigeranalytics.com'
								style={{color: '#378AEA'}}
							>
								https://onedata.tigeranalytics.com
							</a>
						</span>
						<span
							style={{
								fontSize: '12px',
								fontStyle: 'italic',
								marginTop: '10px',
							}}
						>
							Old URL:
							<a
								href='https://onedata.tigeranalyticstest.in'
								style={{color: '#378AEA', textDecoration: 'line-through'}}
							>
								https://onedata.tigeranalyticstest.in
							</a>
						</span>
					</Typography>
					<Grid
						container
						className={styles.loginForm}
					>
						<Grid
							item
							md={12}
							className={styles.form}
						>
							<Onedataicon className={styles.onedataicon} />
						</Grid>
						<Grid
							item
							md={12}
							className={styles.signInBox}
						>
							<Grid
								item
								md={12}
								className={styles.signInBox}
							>
								<a href='https://onedata.tigeranalytics.com'>
									<Button className={styles.signInButton}>
										<Typography
											style={{
												fontWeight: 'bold',
												color: '#f7901d',
												fontSize: '12px',
												fontFamily: 'sans-serif',
												fontStyle: 'italic',
											}}
										>
											Click Here to Open
										</Typography>
									</Button>
								</a>
							</Grid>
						</Grid>
						<Divider style={{width: '100%', marginBottom: '20px'}} />
						<Grid
							container
							className={styles.footContainer}
						>
							<Grid
								item
								md={6}
								style={{
									display: 'flex',
									justifyContent: 'center',
									textAlign: 'center',
									alignItems: 'center',
								}}
							>
								<a
									href='https://www.tigeranalytics.com'
									target='_blank'
									rel='noreferrer'
								>
									<TigerLogo className={styles.tigerLogo} />
								</a>
							</Grid>
							<Grid
								item
								md={6}
								className={styles.copyRight}
								style={{
									display: 'flex',
									justifyContent: 'center',
									textAlign: 'center',
									alignItems: 'center',
								}}
							>
								Copyrights 2023 | All Rights Reserved
							</Grid>
						</Grid>
					</Grid>
				</>
			</div>
		</StyledEngineProvider>
	);
};

export default PageNotFound;
