import * as React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import AlertTitle from '@mui/material/AlertTitle';

export default function CustomAlert(props) {
	const {alert, message, handleClose, success} = props;

	setTimeout(() => {
		handleClose();
	}, 4000);
	return (
		<Stack
			sx={{width: '100%'}}
			spacing={2}
		>
			{alert && (
				<>
					{success ? (
						<Alert
							variant='filled'
							severity='success'
							onClose={handleClose}
							style={{
								'& .MuiAlertRoot': {
									color: 'red',
									fontSize: '180px',
								},
							}}
						>
							{message}
						</Alert>
					) : (
						<Alert
							severity='error'
							onClose={handleClose}
						>
							{message}
						</Alert>
					)}
				</>
			)}
		</Stack>
	);
}
