import {Grid, Button, Typography, Autocomplete} from '@mui/material';
import {TextField} from '@mui/material';
import classes from './AdminForm.module.css';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useOutletContext} from 'react-router-dom';
import {getGroupNames, AddorEditReportsApi} from '../Utilities/reportsApis';
import {getAllGroupsReducer} from '../../redux/adminReducer';

const ReportsForm = (props) => {
	const {handleClose, formdata, handleAlert, getData} = props;
	const googleIdToken = useOutletContext(); // <-- access context value

	const dispatch = useDispatch();
	const reportGroups = useSelector(
		(state) => state.AdminPageData.allReportGroups
	);

	// setting Local state for form Data
	const [reportFormData, setReportFormData] = useState({
		reportName: formdata?.NAME || '',
		pbireportId: formdata?.PBIREPORTID || '',
		workspaceId: formdata?.PBIWORKSPACEID || '',
		Group:
			formdata.GROUP_ID && formdata.GroupName
				? {NAME: formdata.GroupName, ID: formdata.GROUP_ID}
				: null,
	});

	useEffect(() => {
		getGroupNames({googleIdToken}).then((data) => {
			dispatch(getAllGroupsReducer(data));
		});
	}, [dispatch, googleIdToken]);

	// Function to execute ONSubmit
	const onSubmit = async () => {
		// Code To check Empty and Null Values
		const isEmpty = Object.values(reportFormData).some(
			(x) => x === '' || x === null
		);
		const isReportDataEmpty = reportFormData.Group
			? Object.values(reportFormData.Group).some((x) => x === '' || x === null)
			: false;
		if (!isEmpty && !isReportDataEmpty) {
			try {
				AddorEditReportsApi(reportFormData, formdata?.ID, googleIdToken).then(
					(res) => {
						handleAlert(res);
						getData();
						handleClose();
					}
				);
			} catch (err) {
				console.log(err);
			}
		}
	};

	const handleNameChange = (e) => {
		const val = e.target.value;
		// Code for Excluding Numeric and Special Characters
		// if (val.match(/[^A-Za-z]/)) {
		// 	return e.preventDefault();
		// }
		setReportFormData(() => ({
			...reportFormData,
			[e.target.name]: val,
		}));
	};

	// Functio to Handle Email Change
	const handleGroupChange = (e, value) => {
		setReportFormData(() => ({
			...reportFormData,
			Group: {
				NAME: value ? value.NAME : null,
				ID: value ? value.ID : null,
			},
		}));
	};

	return (
		<Grid
			container
			item
			xs={6}
			md={6}
			xl={6}
			className={classes.formContainer}
		>
			<Grid
				container
				className={classes.headingContainer}
			>
				<Grid
					item
					xs={6}
					md={6}
					xl={6}
					sx={{
						display: 'flex',
						justifyContent: 'left',
					}}
				>
					<Typography className={classes.header}>Add Report</Typography>
				</Grid>
			</Grid>
			<Grid
				container
				className={classes.textFieldContainer}
			>
				<Grid
					item
					xs={6}
					md={6}
					xl={6}
					className={classes.nameField}
				>
					<TextField
						label='ReportName'
						variant='standard'
						name='reportName'
						autoComplete='off'
						value={reportFormData.reportName}
						onChange={handleNameChange}
						className={classes.autoComplete}
					/>
				</Grid>
				<Grid
					xs={6}
					md={6}
					xl={6}
					item
					className={classes.emailField}
				>
					<Autocomplete
						renderOption={(props, option) => (
							<Typography
								{...props}
								style={{fontSize: '12px'}}
								key={option?.ID}
							>
								{option?.NAME}
							</Typography>
						)}
						options={reportGroups}
						className={classes.autoComplete}
						value={reportFormData?.Group}
						autoHighlight
						id='GROUP-NAME'
						isOptionEqualToValue={(option, value) =>
							option?.NAME === value?.NAME || value === ''
						}
						getOptionLabel={(option) => (option.NAME ? option.NAME : '')}
						renderInput={(params) => (
							<TextField
								{...params}
								label='GROUP-NAME'
								variant='standard'
							/>
						)}
						onChange={(e, value) => handleGroupChange(e, value)}
					/>
				</Grid>
			</Grid>
			<Grid
				container
				className={classes.textFieldContainer}
			>
				<Grid
					item
					xs={6}
					md={6}
					xl={6}
					className={classes.nameField}
				>
					<TextField
						label='PBIWorkspaceID'
						variant='standard'
						name='workspaceId'
						autoComplete='off'
						value={reportFormData?.workspaceId}
						onChange={handleNameChange}
						className={classes.autoComplete}
					/>
				</Grid>
				<Grid
					item
					xs={6}
					md={6}
					xl={6}
					className={classes.nameField}
				>
					<TextField
						label='PBIReportID'
						variant='standard'
						name='pbireportId'
						autoComplete='off'
						value={reportFormData?.pbireportId}
						onChange={handleNameChange}
						className={classes.autoComplete}
					/>
				</Grid>
			</Grid>
			<Grid
				container
				className={classes.buttonContainer}
			>
				<Button
					// className={classes.clearButton}
					onClick={handleClose}
					style={{
						width: '120px',
						height: '32px',
						color: '#F7901D',
					}}
				>
					Cancel
				</Button>
				<Button
					type='button'
					className={classes.submitButton}
					onClick={onSubmit}
					style={{
						width: '120px',
						height: '32px',
						backgroundColor: '#F7901D',
						color: 'white',
					}}
				>
					Submit
				</Button>
			</Grid>
		</Grid>
	);
};

export default ReportsForm;
