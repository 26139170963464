import React, {useState, useEffect} from 'react';
import Table from '../../components/Table/Table';
import {useDispatch, useSelector} from 'react-redux';
import {Grid, Typography, Box} from '@mui/material';
import classes from './UsersDetails.module.css';
import AdminForm from '../../components/Form/AdminForm';
import {deleteUser} from '../../components/Utilities/apis/deleteApis';
import {getUsers} from '../../redux/adminReducer';
import getAllUsers from '../../components/Utilities/apis/getAllUsers';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import CustomModal from '../../components/Modal/CustomModal';
import CustomAlert from '../../components/Alerts/CustomAlert';
import DeleteScreen from '../../components/DeleteDialog/DeleteScreen';
import Search from '../../components/Search/Search';
import CircularProgress from '@mui/material/CircularProgress';
import Error from '../../components/ErrorPage/Error';

const UsersDetails = () => {
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const [deleteScreenOpen, setDeleteScreenOpen] = useState(false);
	const [deleteRow, setDeleteRow] = useState(null);
	const [userId, setuserId] = useState(null);
	const [alert, setAlert] = useState(false);
	const [alertmessage, setAlertMessage] = useState(null);
	const [success, setSuccess] = useState(false);
	const [formdata, setFormdata] = useState({});
	const [searchValue, setSearchValue] = useState('');
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [text, setText] = useState('');

	const searchFields = [
		'EMAILID',
		'FIRSTNAME',
		'LASTNAME',
		'audienceName',
		'PBI_ROLE',
	];
	const rowdata = useSelector(
		(state) => state.AdminPageData.usersDetails
	)?.filter((row) =>
		searchFields?.some(
			(prop) =>
				row?.[prop] &&
				row?.[prop].toLowerCase().includes(searchValue?.toLowerCase())
		)
	);
	const handleSearch = (event) => {
		event.preventDefault();
		if (event && event.target) {
			setSearchValue(event.target.value);
		}
	};

	const searchReset = () => {
		setSearchValue('');
	};

	const getData = () => {
		getAllUsers().then((data) => {
			if (data.length === 0) {
				setError(true);
				setText('something went wrong');
			} else {
				setError(false);
				dispatch(getUsers(data));
			}
			setLoading(false);
		});
	};

	//  Execute When Page First Loads to get users data
	useEffect(() => {
		getAllUsers().then((data) => {
			if (data.length === 0) {
				setError(true);
				setText('something went wrong');
			} else {
				setError(false);
				dispatch(getUsers(data));
			}
			setLoading(false);
		});
	}, []);

	const handleClose = () => {
		setOpen(false);
		setFormdata({});
		setuserId(null);
	};

	const deleteScreenClose = () => {
		setDeleteScreenOpen(false);
		setDeleteRow(null);
	};

	const handleAlert = (res) => {
		if (res.status === 200) {
			setAlertMessage(res.data.message);
			setAlert(true);
			setSuccess(true);
		} else {
			setAlertMessage(res.response.data.message);
			setAlert(true);
			setSuccess(false);
		}
	};

	const handleAlertClose = () => {
		setAlert(false);
	};

	const addUser = () => {
		setOpen(true);
	};

	const updateUser = (row) => {
		setFormdata(row);
		setuserId(row.userId);
		setOpen(true);
	};
	const deleteUserData = (row) => {
		deleteUser(row).then((res) => {
			handleAlert(res);
			getAllUsers().then((data) => {
				dispatch(getUsers(data));
			});
			deleteScreenClose();
		});
	};

	const handleDelete = (row) => {
		setDeleteRow(row);
		setDeleteScreenOpen(true);
	};

	return (
		<>
			<CustomAlert
				alert={alert}
				message={alertmessage}
				handleClose={handleAlertClose}
				success={success}
			/>
			<Grid
				container
				style={{
					display: 'flex',
					backgroundColor: 'rgb(200, 237, 246)',
					marginBottom: '15px',
				}}
			>
				<Grid
					item
					xs={3}
					md={3}
					xl={3}
					className={classes.right}
				>
					<PeopleOutlineIcon style={{fontSize: '70px'}} />
				</Grid>
				<Grid
					item
					xs={3}
					md={3}
					xl={3}
					className={classes.left}
				>
					<Box className={classes.spacearound}>
						<span style={{fontSize: '18px', fontWeight: 'bold'}}>Users</span>
						<span style={{fontSize: '18px', fontWeight: 'bold'}}>
							{rowdata?.length}
						</span>
					</Box>
				</Grid>
				<Grid
					item
					xs={3}
					md={3}
					xl={3}
					className={classes.left}
					style={{marginTop: '7px'}}
				>
					<Search
						onSearch={handleSearch}
						onClear={searchReset}
						searchValue={searchValue}
					/>
				</Grid>
				<Grid
					item
					xs={3}
					md={3}
					xl={3}
					className={classes.left}
				>
					<Typography
						className={classes.addUserButton}
						onClick={() => addUser()}
					>
						+ Add User
					</Typography>
				</Grid>
			</Grid>
			{error ? (
				<Error
					text={text}
					handleError={getData}
				/>
			) : loading ? (
				<CircularProgress
					style={{position: 'fixed', color: 'orange', top: '50%', right: '50%'}}
				/>
			) : (
				<Table
					editData={updateUser}
					deleteData={handleDelete}
					data={rowdata}
					searchValue={searchValue}
				/>
			)}
			<CustomModal
				open={open}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<>
					<AdminForm
						handleClose={handleClose}
						formdata={formdata}
						userId={userId}
						handleAlert={handleAlert}
					/>
				</>
			</CustomModal>
			<CustomModal
				open={deleteScreenOpen}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<>
					<DeleteScreen
						handleClose={deleteScreenClose}
						deleteData={deleteUserData}
						data={deleteRow}
						Message={`Are you Sure to Delete  ${deleteRow?.EMAILID}`}
					/>
				</>
			</CustomModal>
		</>
	);
};

export default UsersDetails;
