import {Grid, Button, Typography, TextField} from '@mui/material';
import classes from './TwoWayCommunicationHomeResponeForm.module.css';
import {useEffect, useState} from 'react';
import {StyledEngineProvider} from '@mui/material/styles';
import {useOutletContext} from 'react-router-dom';
import {twoWaySingleProjectionUpdate} from '../Utilities/apis/twoWayCommunicationApi';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import CloseIcon from '@mui/icons-material/Close';
import CustomAlert from '../Alerts/CustomAlert';
import CircularProgress from '@mui/material/CircularProgress';

const TwoWayHomeForm = ({homeData, uuid, getData, handleClose}) => {
	const googleIdToken = useOutletContext(); // <-- access context value
	const [formData, setFormData] = useState({});
	const [finalchecked, setFinalChecked] = useState(false);
	const [intialCheck, setInitialChecked] = useState(false);
	const [alert, setAlert] = useState(false);
	const [alertmessage, setAlertMessage] = useState(null);
	const [success, setSuccess] = useState(false);
	const [updatingStatus, setUpdatingStatus] = useState(false);

	const handleAlertClose = () => {
		setAlert(false);
	};
	// Function to execute ONSubmit
	const onSubmit = async () => {
		// Code To check Empty and Null Values
		const isEmpty = Object.values(formData).some(
			(x) =>
				x === '' ||
				x === null ||
				x === false ||
				x === undefined ||
				x === 'undefined'
		);
		if (!isEmpty) {
			setUpdatingStatus(true);
			twoWaySingleProjectionUpdate({googleIdToken, formData, uuid}).then(
				(res) => {
					if (res.status === 200) {
						setAlertMessage(res.data.message);
						setAlert(true);
						setSuccess(true);
					} else {
						setAlertMessage(res.response.data.message);
						setAlert(true);
						setSuccess(false);
					}
					getData();
					setUpdatingStatus(false);
					if (handleClose) {
						setTimeout(() => {
							handleClose();
						}, 500);
					}
				}
			);
		}
	};

	useEffect(() => {
		if (homeData.length > 0) {
			setFormData({
				CustomerGroup: homeData?.[0]?.CUSTOMER_GROUP,
				BU: homeData?.[0]?.BU,
				DP: homeData?.[0]?.DP,
				CP: homeData?.[0]?.CP,
				NETADD_CP: homeData?.[0]?.NETADD_CP,
				NETADD_DOSSIER: homeData?.[0]?.NETADD_DOSSIER,
				NETADD_DP: homeData?.[0]?.NETADD_DP,
				NETADD_CORRECTION: homeData?.[0]?.NETADD_CORRECTION,
				STATUS_UPDATED: homeData?.[0]?.STATUS_UPDATED
					? homeData?.[0]?.STATUS_UPDATED
					: finalchecked,
				REMARKS: homeData?.[0]?.REMARKS,
				CREATED_BY: homeData?.[0]?.CREATED_BY,
			});
			setInitialChecked(homeData?.[0]?.STATUS_UPDATED);
		}
	}, [finalchecked, homeData, homeData.length]);
	return (
		<StyledEngineProvider injectFirst>
			<Grid
				container
				className={classes.main}
			>
				<Grid
					container
					item
					xs={6}
					md={6}
					xl={6}
					className={classes.formContainer}
				>
					<CustomAlert
						alert={alert}
						message={alertmessage}
						handleClose={handleAlertClose}
						success={success}
					/>
					<Grid
						container
						className={classes.heading}
					>
						<Grid
							className={classes.left}
							item
							xs={6}
							md={6}
							xl={6}
						>
							<Typography style={{color: '#312E2D', fontWeight: 'bold'}}>
								Projections
							</Typography>
						</Grid>
						<Grid
							className={classes.right}
							item
							xs={6}
							md={6}
							xl={6}
						>
							{handleClose && (
								<CloseIcon
									className={classes.closeButton}
									onClick={handleClose}
								/>
							)}
						</Grid>
					</Grid>
					<Grid
						xs={6}
						md={6}
						xl={6}
						item
						className={classes.textField}
					>
						<TextField
							disabled
							variant='outlined'
							InputLabelProps={{shrink: true, variant: 'outlined'}}
							label='CUSTOMER_GROUP'
							type='text'
							key={formData?.CustomerGroup}
							value={formData?.CustomerGroup || ''}
						/>
					</Grid>
					<Grid
						xs={6}
						md={6}
						xl={6}
						item
						className={classes.textField}
					>
						<TextField
							disabled
							variant='outlined'
							InputLabelProps={{shrink: true, variant: 'outlined'}}
							label='CLIENT_PARTNER'
							type='text'
							key={formData?.CP}
							value={formData?.CP || ''}
						/>
					</Grid>
					<Grid
						xs={6}
						md={6}
						xl={6}
						item
						className={classes.textField}
					>
						<TextField
							disabled
							variant='outlined'
							InputLabelProps={{shrink: true, variant: 'outlined'}}
							label='DELIVERY_PARTNER'
							type='text'
							key={formData?.DP}
							value={formData?.DP || ''}
						/>
					</Grid>
					<Grid
						xs={6}
						md={6}
						xl={6}
						item
						className={classes.textField}
					>
						<TextField
							disabled
							variant='outlined'
							InputLabelProps={{shrink: true, variant: 'outlined'}}
							label='BU'
							type='text'
							key={formData?.BU}
							value={formData?.BU || ''}
						/>
					</Grid>
					<Grid
						xs={6}
						md={6}
						xl={6}
						item
						className={classes.textField}
					>
						<TextField
							disabled
							variant='outlined'
							InputLabelProps={{shrink: true, variant: 'outlined'}}
							label='RAISED_BY'
							type='text'
							key={formData?.CREATED_BY}
							value={formData?.CREATED_BY || ''}
						/>
					</Grid>
					<Grid
						xs={6}
						md={6}
						xl={6}
						item
						className={classes.textField}
					>
						<FormControlLabel
							control={
								<Checkbox
									checked={formData.STATUS_UPDATED || finalchecked}
									onChange={(e, value) => setFinalChecked(value)}
								/>
							}
							label='Update Status here'
							disabled={intialCheck}
						/>
					</Grid>
					<Grid
						container
						className={classes.textField}
					>
						<Grid
							item
							md={2}
							xl={2}
							xs={2}
							className={classes.numField}
						>
							<TextField
								disabled
								variant='outlined'
								InputLabelProps={{shrink: true, variant: 'outlined'}}
								label='NETADD_CP'
								type='number'
								key={formData?.NETADD_CP}
								value={formData?.NETADD_CP || 0}
							/>
						</Grid>
						<Grid
							item
							md={2}
							xl={2}
							xs={2}
							className={classes.numField}
						>
							<TextField
								disabled
								variant='outlined'
								InputLabelProps={{shrink: true, variant: 'outlined'}}
								label='NETADD_DP'
								type='number'
								key={formData?.NETADD_DP}
								value={formData?.NETADD_DP || 0}
							/>
						</Grid>
						<Grid
							item
							md={2}
							xl={2}
							xs={2}
							className={classes.numField}
						>
							<TextField
								disabled
								variant='outlined'
								InputLabelProps={{shrink: true, variant: 'outlined'}}
								label='NETADD_DOSSIER'
								type='number'
								key={formData?.NETADD_DOSSIER}
								value={formData?.NETADD_DOSSIER || 0}
							/>
						</Grid>
						<Grid
							item
							md={2}
							xl={2}
							xs={2}
							className={classes.numField}
						>
							<TextField
								disabled
								variant='outlined'
								InputLabelProps={{shrink: true, variant: 'outlined'}}
								label='NETADD_CORRECTION'
								type='number'
								key={formData?.NETADD_CORRECTION}
								value={formData?.NETADD_CORRECTION}
							/>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						md={12}
						xl={12}
						className={classes.dataField}
					>
						<TextField
							id='outlined-multiline-flexible'
							label='Remarks'
							name='remarks'
							disabled
							autoFocus
							multiline
							fullWidth
							maxRows={4}
							value={formData?.REMARKS || ''}
							className={classes.autoComplete}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={12}
						xl={12}
						className={classes.buttonContainer}
					>
						{updatingStatus ? (
							<Button className={classes.submitButton}>
								<CircularProgress
									className={classes.loader}
									style={{width: '15px', height: '15px'}}
								/>
							</Button>
						) : (
							<Button
								className={classes.submitButton}
								onClick={onSubmit}
								disabled={intialCheck}
							>
								Submit
							</Button>
						)}
					</Grid>
				</Grid>
			</Grid>
		</StyledEngineProvider>
	);
};

export default TwoWayHomeForm;
