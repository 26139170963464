import React, {useEffect, useState} from 'react';
import {useParams, useOutletContext} from 'react-router-dom';
import {twoWayHomeData} from '../../components/Utilities/apis/twoWayCommunicationApi';
import Error from '../../components/ErrorPage/Error';
import TwoWayHomeForm from '../../components/Form/TwoWayCommunicationHomeResponseForm';
import {StyledEngineProvider} from '@mui/material/styles';
import Header from '../../components/Header/Header';
import {Box} from '@mui/material';
import classes from './TwoWayFormResponse.module.css';

const TwoWayHome = () => {
	const {id, uuid} = useParams();
	const googleIdToken = useOutletContext(); // <-- access context value
	const [homeData, setHomeData] = useState([]);
	const [error, setError] = useState(false);
	const [text, setText] = useState('');
	const getData = () => {
		twoWayHomeData({googleIdToken, id, uuid}).then((res) => {
			if (res.length === 0) {
				setError(true);
				setText('something went wrong');
			} else {
				setError(false);
				setHomeData(res);
			}
		});
	};

	useEffect(() => {
		twoWayHomeData({googleIdToken, id, uuid}).then((res) => {
			if (res.length === 0) {
				setError(true);
				setText('something went wrong');
			}
			setHomeData(res);
		});
	}, [googleIdToken, id, uuid]);
	return (
		<StyledEngineProvider injectFirst>
			<Header />
			{error ? (
				<Error
					text={text}
					handleError={getData}
				/>
			) : (
				<Box className={classes.twoWayResponseBody}>
					<TwoWayHomeForm
						homeData={homeData}
						uuid={uuid}
						getData={getData}
					/>
				</Box>
			)}
		</StyledEngineProvider>
	);
};

export default TwoWayHome;
