// Function to Get All Users Data to Admin Screen table
import axios from 'axios';
import config from '../../../config';

const getAllAudience = async () => {
	const token = localStorage.getItem('googleIdToken');
	try {
		const result = await axios.get(`${config.apiURL}/getAllAudience`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		return Array.isArray(result?.data) ? result?.data : [];
	} catch (err) {
		return [];
	}
};

const getAudienceReport = async (audienceId) => {
	const token = localStorage.getItem('googleIdToken');
	try {
		const result = await axios.get(
			`${config.apiURL}/getAudienceReport/${audienceId}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return Array.isArray(result?.data) ? result?.data : [];
	} catch (err) {
		return [];
	}
};
const saveAudience = async (formData) => {
	const token = localStorage.getItem('googleIdToken');
	try {
		const res = await axios.post(
			`${config.apiURL}/saveAudience`,
			JSON.stringify(formData), // Stringify formData if it's not in the correct format
			{
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json', // Set Content-Type to application/json since we're sending JSON data
				},
			}
		);
		return res;
	} catch (err) {
		console.log(err);
	}
};

export {saveAudience, getAllAudience, getAudienceReport};
