import * as React from 'react';
import {useEffect, useState} from 'react';
import {getAudienceReport} from '../Utilities/apis/audienceApi';
import {TextField, Typography, Autocomplete, Grid} from '@mui/material';
import classes from './MultiSelectStyles.module.css';
import audienceToReportsMapping from '../Utilities/apis/audienceToReportMappingApi';
import {getAudienceReport as getAudienceReportAction} from '../../redux/adminReducer';
import {useDispatch} from 'react-redux';

export default function MultipleSelectComponent(props) {
	const dispatch = useDispatch();
	const {audienceReport, handleSubmitOpen, audienceId, handleAlert} = props;
	const [selectedInputs, setSelectedInputs] = useState([]);
	const filterObject = audienceReport.reduce((ac, record) => {
		if (record.PBIREPORTID) {
			return {
				...ac,
				[record.PBIREPORTID]: record,
			};
		}
		return ac;
	}, {});
	const [allReports, setAllReports] = useState([]);
	const ListOfReportsToAdd = allReports.filter(
		(item) => !filterObject[item.PBIREPORTID]
	);
	const handleChange = (e, value) => {
		setSelectedInputs([...value]);
	};
	const handleAddReports = () => {
		const result = audienceToReportsMapping({selectedInputs, audienceId}).then(
			(res) => {
				handleAlert(res);
				getAudienceReport(audienceId).then((data) => {
					dispatch(getAudienceReportAction(data));
				});
			}
		);
		handleSubmitOpen();
	};

	useEffect(() => {
		getAudienceReport().then((result) => {
			setAllReports(result);
		});
	}, []);

	return (
		<Grid
			container
			style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
			spacing={2}
		>
			<Grid
				item
				md={11}
				xs={11}
				xl={11}
				className={classes.right}
			>
				<Autocomplete
					multiple
					renderOption={(props, option) => (
						<Typography
							{...props}
							style={{fontSize: '12px'}}
							key={option.PBIREPORTID}
						>
							{`${option?.NAME}- ${option?.GroupName}`}
						</Typography>
					)}
					options={ListOfReportsToAdd || []}
					isOptionEqualToValue={(option, value) =>
						option.PBIREPORTID === value.PBIREPORTID || value === ''
					}
					getOptionLabel={(option) => (option.NAME ? option.NAME : '')}
					id='Audience'
					autoHighlight
					value={selectedInputs}
					className={classes.autoComplete}
					renderInput={(params) => (
						<TextField
							{...params}
							label='Reports'
							variant='outlined'
							size='small'
						/>
					)}
					onChange={(e, value) => handleChange(e, value)}
				/>
			</Grid>
			<Grid
				item
				md={1}
				xs={1}
				xl={1}
			>
				<Typography
					className={classes.addButton}
					onClick={handleAddReports}
				>
					Add
				</Typography>
			</Grid>
		</Grid>
	);
}
