import {Typography, Grid, Box} from '@mui/material';
import React, {useEffect, useState} from 'react';
import classes from './SideBarComponent.module.css';
import {useSelector, useDispatch} from 'react-redux';
import {addSelectedReport} from '../../../redux/userReducer';

const SideBarComponent = (props) => {
	const selectedReportGlobal = useSelector(
		(state) => state.PowerBi.selectedReport
	);
	const {reports, handleReports} = props;
	const dispatch = useDispatch();
	const [selectedreport, setSelectedReport] = useState(null);
	const [selectedGroup, setSelectedGroup] = useState(null);

	const [open, setOpen] = useState(null);

	// Function for display menu list to click left side icon

	const handleClick = (report) => {
		setSelectedReport(report?.PBIREPORTID);
		setSelectedGroup(report?.NAME);
		dispatch(addSelectedReport(report));
		handleReports(report);
	};

	useEffect(() => {
		if (Object.keys(selectedReportGlobal).length !== 0) {
			setSelectedReport(selectedReportGlobal?.PBIREPORTID);
			setSelectedGroup(selectedReportGlobal?.NAME);
		}
	}, [selectedReportGlobal]);

	return (
		<Grid
			container
			className={classes.customScroll}
			style={{maxHeight: '100%', overflowY: 'auto'}}
		>
			{reports.length > 0 &&
				reports.map((arr, indexTop) => (
					<Grid
						container
						className={
							reports.length - 1 === indexTop ? classes.lastMain : classes.main
						}
						key={indexTop}
					>
						<Grid
							item
							md={3}
							xs={3}
							xl={3}
							className={
								selectedGroup === arr[0] ? classes.selectedLeft : classes.left
							}
						>
							<div
								className={
									selectedGroup === arr[0]
										? classes.selectedtitle
										: classes.title
								}
							>
								<Typography className={classes.titleText}>{arr[0]}</Typography>
							</div>
						</Grid>
						<Grid
							item
							md={9}
							xs={9}
							xl={9}
							className={classes.right}
						>
							{arr[1].slice(0, 4).map((report, index) => (
								<>
									<Grid
										item
										key={report.reportId}
										className={
											selectedreport === report.PBIREPORTID
												? classes.selectedReport
												: classes.report
										}
										onClick={() => handleClick(report)}
									>
										<span className={classes.reportfontStyle}>
											{report.ReportName}
										</span>
									</Grid>
								</>
							))}
							{arr[1].length > 4 && (
								<Box
									id='box'
									className={classes.extraReports}
								>
									<span className={classes.extraReportText}>
										{arr[0] === open ? (
											<span
												onClick={(e) => {
													setOpen(null);
												}}
												style={{cursor: 'pointer'}}
											>
												less
											</span>
										) : (
											<span
												onClick={(e) => {
													setOpen(arr[0]);
												}}
												style={{cursor: 'pointer'}}
											>
												{`${arr[1].length - 4} more`}
											</span>
										)}
									</span>
								</Box>
							)}
							{arr[0] === open &&
								arr[1].slice(4, arr[1].length).map((report, index) => (
									<Grid
										item
										key={report.reportId}
										className={
											selectedreport === report.PBIREPORTID
												? classes.selectedReport
												: classes.report
										}
										onClick={() => handleClick(report)}
									>
										<span className={classes.reportfontStyle}>
											{report.ReportName}
										</span>
									</Grid>
								))}
						</Grid>
					</Grid>
				))}
		</Grid>
	);
};

export default SideBarComponent;
