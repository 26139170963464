import axios from 'axios';
import config from '../../config';
const updateUser = async (props) => {
	const token = localStorage.getItem('googleIdToken');
	try {
		const res = await axios.put(`${config.apiURL}/updateUser`, {
			Authorization: `Bearer ${token}`,
			props,
		});
		return res;
	} catch (err) {
		return err;
	}
};
export default updateUser;
