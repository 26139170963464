import {createSlice} from '@reduxjs/toolkit';

const glossaryReducer = createSlice({
	name: 'glossary OneData',
	initialState: {
		glossaryData: [],
	},
	reducers: {
		getGlossary(state, action) {
			state.glossaryData = action.payload;
		},
	},
});

export const {getGlossary} = glossaryReducer.actions;
export default glossaryReducer.reducer;
