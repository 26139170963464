import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {GoogleOAuthProvider} from '@react-oauth/google';
import {store, persistor} from './redux/store';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<PersistGate
				loading={null}
				persistor={persistor}
			>
				<GoogleOAuthProvider clientId='572745194466-vlnnffp6pcb94n9i7q5i0v4i7trh59oe.apps.googleusercontent.com'>
					<App />
				</GoogleOAuthProvider>
			</PersistGate>
		</Provider>
	</React.StrictMode>
);

reportWebVitals();
