import axios from 'axios';
import config from '../../../config';

const deleteUser = async (props) => {
	const token = localStorage.getItem('googleIdToken');
	try {
		const res = await axios.delete(`${config.apiURL}/deleteUser`, {
			data: {props, Authorization: `Bearer ${token}`},
		});
		return res;
	} catch (err) {
		return err;
	}
};
const deleteAudience = async (props) => {
	const token = localStorage.getItem('googleIdToken');
	try {
		const res = await axios.delete(`${config.apiURL}/deleteAudience`, {
			data: {props, Authorization: `Bearer ${token}`},
		});
		return res;
	} catch (err) {
		return err;
	}
};

const deleteReportToAudience = async (props) => {
	const token = localStorage.getItem('googleIdToken');
	try {
		const res = await axios.delete(`${config.apiURL}/deleteReportToAudience`, {
			data: {props, Authorization: `Bearer ${token}`},
		});
		return res;
	} catch (err) {
		return err;
	}
};

export {deleteUser, deleteAudience, deleteReportToAudience};
