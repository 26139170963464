import {Modal} from '@mui/material';

import React from 'react';

const CustomModal = (props) => {
	const {children, open, sx} = props;
	return (
		<Modal
			open={open}
			aria-labelledby='modal-modal-title'
			aria-describedby='modal-modal-description'
			sx={sx}
		>
			{children}
		</Modal>
	);
};

export default CustomModal;
