import Box from '@mui/material/Box';
import {StyledEngineProvider} from '@mui/material/styles';
import classes from './Table.module.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Highlighter from 'react-highlight-words';
import {useEffect, useState} from 'react';
const CustomTable = ({editData, deleteData, data: rowdata, searchValue}) => {
	const [rows, setRows] = useState([]);
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('EMAILID');

	useEffect(() => {
		if (rowdata && Array.isArray(rowdata)) {
			setRows(rowdata);
		}
	}, [rowdata]);

	const handleRequestSort = (property) => {
		const isAsc = orderBy === property && order === 'asc';
		const newOrder = isAsc ? 'desc' : 'asc';
		const sortedRows = [...rows].sort((a, b) => {
			if (a[property] < b[property]) {
				return newOrder === 'asc' ? -1 : 1;
			}
			if (a[property] > b[property]) {
				return newOrder === 'asc' ? 1 : -1;
			}
			return 0;
		});
		setOrder(newOrder);
		setOrderBy(property);
		setRows(sortedRows);
	};

	return (
		<StyledEngineProvider injectFirst>
			<Box>
				<TableContainer
					component={Paper}
					className={classes.table}
					sx={{
						'&::-webkit-scrollbar': {
							width: 4,
						},
						'&::-webkit-scrollbar-track': {
							backgroundColor: '#F4F4F4',
						},
						'&::-webkit-scrollbar-thumb': {
							backgroundColor: 'rgb(37, 200, 241)',
						},
					}}
					style={{minWidth: 650, maxHeight: (window.screen.height * 50) / 100}}
				>
					<Table aria-label='simple table'>
						<TableHead>
							<TableRow
								sx={{
									backgroundColor: 'rgb(37, 200, 241)',
									fontSize: '14px',
									color: '#312E2D',
									fontWeight: 'bold',
								}}
							>
								<TableCell
									sx={{fontWeight: 'bold', padding: '2.5px'}}
									align='center'
								>
									S-No
								</TableCell>
								<TableCell sx={{fontWeight: 'bold', padding: '2.5px'}}>
									<TableSortLabel
										active={orderBy === 'EMAILID'}
										direction={orderBy === 'EMAILID' ? order : 'asc'}
										onClick={() => handleRequestSort('EMAILID')}
									>
										EMAILID
									</TableSortLabel>
								</TableCell>
								<TableCell
									sx={{fontWeight: 'bold', padding: '2.5px'}}
									align='left'
								>
									Name
								</TableCell>
								<TableCell
									sx={{fontWeight: 'bold', padding: '2.5px'}}
									align='left'
								>
									<TableSortLabel
										active={orderBy === 'audienceName'}
										direction={orderBy === 'audienceName' ? order : 'asc'}
										onClick={() => handleRequestSort('audienceName')}
									>
										audienceName
									</TableSortLabel>
								</TableCell>
								<TableCell
									sx={{fontWeight: 'bold', padding: '2.5px'}}
									align='left'
								>
									<TableSortLabel
										active={orderBy === 'PBI_ROLE'}
										direction={orderBy === 'PBI_ROLE' ? order : 'asc'}
										onClick={() => handleRequestSort('PBI_ROLE')}
									>
										PBI_ROLE
									</TableSortLabel>
								</TableCell>
								<TableCell
									sx={{fontWeight: 'bold', padding: '2.5px'}}
									align='center'
								>
									Actions
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{rows?.length > 0 &&
								rows?.map((row, id) => (
									<TableRow
										key={id}
										sx={{
											'&:last-child td, &:last-child th': {border: 0},
											'&:nth-of-type(even)': {
												backgroundColor: 'white',
											},
											'&:nth-of-type(odd)': {
												backgroundColor: 'rgb(200, 237, 246)',
											},
										}}
									>
										<TableCell
											align='center'
											sx={{padding: '2.5px'}}
										>
											{id + 1}
										</TableCell>
										<TableCell
											component='th'
											scope='row'
											align='left'
											sx={{padding: '2.5px'}}
										>
											<Highlighter
												highlightStyle={{
													borderRadius: 5,
												}}
												highlightClassName={classes.highlighterText}
												searchWords={[searchValue]}
												autoEscape={true}
												textToHighlight={row?.EMAILID || ''}
											/>
										</TableCell>
										<TableCell
											align='left'
											sx={{padding: '2.5px'}}
										>
											<Highlighter
												highlightStyle={{
													borderRadius: 5,
												}}
												highlightClassName={classes.highlighterText}
												searchWords={[searchValue]}
												autoEscape={true}
												textToHighlight={
													`${row?.FIRSTNAME} ${row?.LASTNAME}` || ''
												}
											/>
										</TableCell>
										<TableCell
											align='left'
											sx={{padding: '2.5px'}}
										>
											<Highlighter
												highlightStyle={{
													borderRadius: 5,
												}}
												highlightClassName={classes.highlighterText}
												searchWords={[searchValue]}
												autoEscape={true}
												textToHighlight={row.audienceName || ''}
											/>
										</TableCell>
										<TableCell
											align='left'
											sx={{padding: '2.5px'}}
										>
											<Highlighter
												highlightStyle={{
													borderRadius: 5,
												}}
												highlightClassName={classes.highlighterText}
												searchWords={[searchValue]}
												autoEscape={true}
												textToHighlight={row.PBI_ROLE || ''}
											/>
										</TableCell>
										<TableCell
											align='center'
											sx={{padding: '2.5px'}}
										>
											<EditIcon
												className={classes.editIcon}
												onClick={() => editData(row)}
											/>
											<DeleteOutlineIcon
												className={classes.deleteIcon}
												onClick={() => deleteData(row)}
											/>
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</StyledEngineProvider>
	);
};

export default CustomTable;
