// Function to Get All Users Data to Admin Screen table
import axios from 'axios';
import config from '../../config';

const getAllProjectionRemarks = async ({googleIdToken}) => {
	try {
		const result = await axios.get(`${config.apiURL}/getAllRemarks`, {
			headers: {
				Authorization: `Bearer ${googleIdToken}`,
			},
		});
		return Array.isArray(result?.data) ? result?.data : [];
	} catch (err) {
		return [];
	}
};

export default getAllProjectionRemarks;
