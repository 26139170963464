/*
This component contains source code that creates a search element.
It allows user to search by text and search text will be hightlighting with some color in the page.
*/
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import {InputBase, Box} from '@mui/material';
import classes from './Search.module.css';
import CloseIcon from '@mui/icons-material/Close';

const Search = (props) => {
	const {onSearch, onClear, searchValue} = props;

	return (
		<Box className={classes.container}>
			<SearchIcon
				fontSize='inherit'
				className={classes.searchIcon}
			/>
			<InputBase
				className={classes.input}
				onChange={onSearch}
				placeholder='Search'
				autoFocus
				value={searchValue}
			/>
			<CloseIcon
				className={classes.closeButton}
				onClick={onClear}
			/>
		</Box>
	);
};

export default Search;
