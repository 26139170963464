// Function to Get All Users Data to Admin Screen table
import axios from 'axios';
import config from '../../../config';

const getAllUsers = async () => {
	const token = localStorage.getItem('googleIdToken');
	try {
		const result = await axios.get(`${config.apiURL}/getAllUsers`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		return Array.isArray(result?.data) ? result?.data : [];
	} catch (err) {
		return [];
	}
};

const getAllUsersByAudienceId = async (audienceId) => {
	const token = localStorage.getItem('googleIdToken');
	try {
		const result = await axios.get(`${config.apiURL}/getAllUsersByAudienceId`, {
			headers: {
				Authorization: `Bearer ${token}`,
				audienceId,
			},
		});
		return Array.isArray(result?.data) ? result?.data : [];
	} catch (err) {
		return [];
	}
};

export default getAllUsers;
export {getAllUsersByAudienceId};
