import classes from './Error.module.css';
import {Box, Typography} from '@mui/material';
import {ReactComponent as ErrorLogo} from '../../components/Assets/Error_symbol.svg';

const Error = ({text, handleError}) => {
	return (
		<Box className={classes.Error}>
			<Box
				className={classes.errorBox}
				style={{top: '40%', left: '50%', position: 'fixed'}}
			>
				<ErrorLogo />
				<span className={classes.oopsText}>Oops!</span>
				<Typography className={classes.errorMessage}>{text}</Typography>
				<Box
					className={classes.refreshButton}
					onClick={handleError}
				>
					Refresh
				</Box>
			</Box>
		</Box>
	);
};

export default Error;
