import {createSlice} from '@reduxjs/toolkit';

const formReducer = createSlice({
	name: 'FormDetails',
	initialState: {
		AudienceValues: [],
		TwoWayFormData: [],
	},
	reducers: {
		getAudiences(state, action) {
			state.AudienceValues = action.payload;
		},
		setTwoWayFormData(state, action) {
			state.TwoWayFormData = action.payload;
		},
	},
});

export const {getAudiences, setTwoWayFormData} = formReducer.actions;
export default formReducer.reducer;
