import React, {useState} from 'react';
import './PowerBiReport.css';
import {models} from 'powerbi-client';
import {PowerBIEmbed} from 'powerbi-client-react';
import {addReportSlice} from '../../redux/userReducer';
import {useDispatch, useSelector} from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import {Button} from '@mui/material';
import TwoWayCommunication from '../TwoWayCommunication/TwoWayCommunication';
import classes from './floatDivStyles.module.css';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import DraggableModal from '../../components/Modal/DraggableModal';
const PowerBIReport = (props) => {
	//code to Zoom the report , will set if needed
	// let currentZoom = await window.Report.getZoom();
	// await window.Report.setZoom(1);
	// zoomLevel: 2,
	const {selectedReport, embedUrl, embedToken, handleEmbedError} = props;
	const [openEditIcons, setOpenEditIcons] = useState(false);
	const [open, setOpen] = useState(false);
	const dispatch = useDispatch();
	const allSliceData = useSelector((state) => state.PowerBi.reportSliceState);
	let user = useSelector((state) => state.PowerBi.user);
	const handleEditButtons = () => {
		setOpenEditIcons(!openEditIcons);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const handleGlossary = () => {
		setOpen(true);
	};

	return (
		<div
			style={{
				display: 'flex',
			}}
		>
			<div style={{flex: 1}}>
				<PowerBIEmbed
					cssClassName={'PowerBiReport'}
					embedConfig={{
						type: 'report', // Supported types: report, dashboard, tile, visual and qna
						id: selectedReport?.PBIREPORTID,
						pageName: 'TA_OneData_PBI_Embed',
						embedUrl: embedUrl,
						accessToken: embedToken,
						tokenType: models.TokenType.Embed,
						slicers: allSliceData[selectedReport?.PBIREPORTID],
						settings: {
							panes: {
								filters: {
									visible: false,
								},
								pageNavigation: {
									visible: false,
								},
							},
							filterPaneEnabled: false,
							navContentPaneEnabled: true,
							background: 0,
						},
					}}
					eventHandlers={
						new Map([
							[
								'loaded',
								async function () {
									console.log('coming to Load');
								},
							],
							[
								'rendered',
								async function () {
									const pages = await window.Report.getPages();
									// Retrieve the active page.
									let page = pages.filter(function (page) {
										return page.isActive;
									})[0];
									const visuals = await page.getVisuals();
									// Retrieve the target visual.
									let slicerS = visuals.filter(function (visual) {
										return visual.type === 'slicer';
									});
									const slices = slicerS.map(async (slicer) => {
										const s = await slicer.getSlicerState();
										return [s.filters, slicer.name];
									});
									const allSlicesReport = await Promise.all(slices);
									dispatch(
										addReportSlice([
											allSlicesReport.filter((item) => item[0].length > 0),
											window.Report.config.id,
											page.displayName,
										])
									);
								},
							],
							[
								'error',
								function (event) {
									// handleEmbedError();
									console.log(event);
									if (
										event.detail.errorCode == 401 ||
										event.detail.errorCode == 403
									) {
										handleEmbedError();
									}
								},
							],
						])
					}
					getEmbeddedComponent={(embeddedReport) => {
						window.Report = embeddedReport;
					}}
				/>
			</div>
			<DraggableModal
				open={open}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<>
					<TwoWayCommunication handleClose={handleClose} />
				</>
			</DraggableModal>
			{selectedReport?.ReportName === 'Projections' && user?.ISADMIN && (
				<div className={classes.floatDiv}>
					{openEditIcons && (
						<div style={{float: 'left'}}>
							<Button
								variant='contained'
								aria-label='edit'
								endIcon={<EditIcon />}
								onClick={handleGlossary}
								sx={{
									backgroundColor: '#f7901d',
									color: '#ffffff',
									border: '2px solid #ffffff',
									borderRadius: '30px',
									'&:hover': {
										backgroundColor: '#f7901d',
										borderColor: '#ffffff',
									},
									textAlign: 'center',
								}}
							>
								Dossier Correction
							</Button>
							<a
								href='https://www.appsheet.com/start/1525b785-2afc-4404-9f2a-c673c245eec7'
								target='_blank'
								rel='noopener noreferrer'
							>
								<Button
									variant='contained'
									aria-label='edit'
									endIcon={<EditIcon />}
									sx={{
										backgroundColor: '#f7901d',
										color: '#ffffff',
										border: '2px solid #ffffff',
										borderRadius: '30px',
										'&:hover': {
											backgroundColor: '#f7901d',
											borderColor: '#ffffff',
										},
										textAlign: 'center',
									}}
								>
									Projections Correction
								</Button>
							</a>
						</div>
					)}
					<Button
						variant='contained'
						aria-label='edit'
						onClick={handleEditButtons}
						endIcon={<KeyboardDoubleArrowRightIcon />}
						sx={{
							backgroundColor: '#f7901d',
							color: '#ffffff',
							border: '2px solid #ffffff',
							borderRadius: '30px',
							'&:hover': {
								backgroundColor: '#f7901d',
								borderColor: '#ffffff',
							},
						}}
					>
						Corrections
					</Button>
				</div>
			)}
		</div>
	);
};

export default PowerBIReport;
