import {useState} from 'react';
import classes from './EditMenuStyles.module.css';
import {Button, Grid} from '@mui/material';
import {StyledEngineProvider} from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {useNavigate, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
	addDefaultReport,
	addSelectedReport,
	addUser,
	resetUser,
} from '../../redux/userReducer';
import removeTokens from '../Utilities/handleLogout';

export default function EditMenu({anchorEl, handleEditProfileClose}) {
	const dispatch = useDispatch();
	const [editMenuOpen, setEditMenuOpen] = useState(false);
	const userRoles = useSelector((state) => state.PowerBi.userAllRoles);
	const user = useSelector((state) => state.PowerBi.user);
	const open = Boolean(anchorEl);
	const location = useLocation();
	const navigate = useNavigate();

	const handleLogout = () => {
		removeTokens();
		dispatch(resetUser({}));
		navigate('/', {state: {location: location}});
	};
	const handleEdit = () => {
		setEditMenuOpen(true);
	};
	const handleUserRoleChange = (user) => {
		dispatch(addUser(user));
		dispatch(addSelectedReport({}));
		dispatch(addDefaultReport({}));
	};

	return (
		<StyledEngineProvider injectFirst>
			<Menu
				id='basic-menu'
				anchorEl={anchorEl}
				open={open}
				onClose={handleEditProfileClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
				className={classes.Menu}
				sx={{
					mt: '1px',
					'& .MuiMenu-paper': {
						backgroundColor: '#312E2D',
					},
				}}
			>
				{/* <MenuItem onClick={handleEdit}>Change Audience</MenuItem> */}
				{/* {editMenuOpen && (
					<Grid
						container
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
							backgroundColor: '#312E2D',
						}}
					>
						{userRoles[1].map((userrole) => (
							<Grid
								className={
									user.ID === userrole.ID ? classes.selectedItem : classes.item
								}
								onClick={() => handleUserRoleChange(userrole)}
							>
								{userrole.PBI_ROLE}
							</Grid>
						))}
					</Grid>
				)} */}
				<MenuItem
					onClick={handleLogout}
					style={{}}
					className={classes.MenuItem}
				>
					Logout
				</MenuItem>
			</Menu>
		</StyledEngineProvider>
	);
}
