import axios from 'axios';
import config from '../../../config';
import loginActivity from './loginActivity';

const loginApi = async (credentialResponse) => {
	try {
		const result = await axios.get(`${config.apiURL}/validateUserByEmail`, {
			headers: {
				Authorization: `Bearer ${credentialResponse}`,
			},
		});
		if (Object.keys(result.data).length !== 0) {
			loginActivity([result.data.ID, 1]);
			return result.data;
		}
		return {};
	} catch (err) {
		return {};
	}
};

export default loginApi;
