const getURL = () => {
	if (window.location.href.includes('localhost')) {
		//put port 6000 only while deploying to  prod
		return 'http://localhost:4000';
	}
	let protocol = 'https://';
	let baseUrl = 'onedatabeprod.azurewebsites.net';
	return protocol.concat(baseUrl);
};

const config = {
	apiURL: getURL(),
};

export default config;
