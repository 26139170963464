import React, {useState, useEffect} from 'react';
import {useOutletContext} from 'react-router-dom';
import {Grid, Box, Typography} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import CustomModal from '../../components/Modal/CustomModal';
import {getReports, deleteReport} from '../../components/Utilities/reportsApis';
import {getReportsReducer} from '../../redux/adminReducer';
import ReportsTable from './ReportsTable';
import CustomAlert from '../../components/Alerts/CustomAlert';
import SpatialAudioOffIcon from '@mui/icons-material/SpatialAudioOff';
import classes from './ReportPage.module.css';
import ReportsForm from '../../components/Form/ReportsForm';
import DeleteScreen from '../../components/DeleteDialog/DeleteScreen';

const ReportPage = () => {
	const [open, setOpen] = useState(false);
	const dispatch = useDispatch();
	const [formdata, setFormdata] = useState({});
	const [deleteScreenOpen, setDeleteScreenOpen] = useState(false);
	const [deleteRow, setDeleteRow] = useState(null);
	const [alert, setAlert] = useState(false);
	const [alertmessage, setAlertMessage] = useState(null);
	const [success, setSuccess] = useState(false);
	const rowdata = useSelector((state) => state.AdminPageData.allReports);
	const googleIdToken = useOutletContext(); // <-- access context value
	const getData = () => {
		getReports({googleIdToken}).then((data) => {
			dispatch(getReportsReducer(data));
		});
	};

	const handleAlert = (res) => {
		if (res.status === 200) {
			setAlertMessage(res.data.message);
			setAlert(true);
			setSuccess(true);
		} else {
			setAlertMessage(res.response.data.message);
			setAlert(true);
			setSuccess(false);
		}
	};

	const handleAlertClose = () => {
		setAlert(false);
	};

	const deleteScreenClose = () => {
		setDeleteScreenOpen(false);
		setDeleteRow(null);
	};

	const handleDelete = (row) => {
		setDeleteRow(row);
		setDeleteScreenOpen(true);
	};

	const updateAndAddReport = (row) => {
		setFormdata(row);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setFormdata({});
	};

	const deleteReportData = (row) => {
		deleteReport(row, googleIdToken).then((res) => {
			handleAlert(res);
			getReports({googleIdToken}).then((data) => {
				dispatch(getReportsReducer(data));
			});
			deleteScreenClose();
		});
	};

	//  Execute When Page First Loads to get users data
	useEffect(() => {
		getReports({googleIdToken}).then((data) => {
			dispatch(getReportsReducer(data));
		});
	}, [dispatch, googleIdToken]);

	return (
		<>
			<CustomAlert
				alert={alert}
				message={alertmessage}
				handleClose={handleAlertClose}
				success={success}
			/>
			<Grid
				container
				style={{
					display: 'flex',
					backgroundColor: 'rgb(200, 237, 246)',
					marginBottom: '15px',
				}}
			>
				<Grid
					item
					xs={3}
					md={3}
					xl={3}
					style={{
						display: 'flex',
						justifyContent: 'right',
					}}
				>
					<SpatialAudioOffIcon style={{fontSize: '70px'}} />
				</Grid>
				<Grid
					item
					xs={3}
					md={3}
					xl={3}
					style={{
						display: 'flex',
						justifyContent: 'left',
					}}
				>
					<Box
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-around',
						}}
					>
						<span style={{fontSize: '18px', fontWeight: 'bold'}}>
							No. of Reports
						</span>
						<span style={{fontSize: '18px', fontWeight: 'bold'}}>
							{rowdata?.length}
						</span>
					</Box>
				</Grid>
				<Grid
					item
					xs={6}
					md={6}
					xl={6}
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Typography
						className={classes.addReportButton}
						onClick={updateAndAddReport}
					>
						+ Add Reports
					</Typography>
				</Grid>
			</Grid>
			<ReportsTable
				editData={updateAndAddReport}
				deleteData={handleDelete}
				data={rowdata}
			/>
			<CustomModal
				open={open}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<>
					<ReportsForm
						handleClose={handleClose}
						formdata={formdata}
						handleAlert={handleAlert}
						getData={getData}
					/>
				</>
			</CustomModal>

			<CustomModal
				open={deleteScreenOpen}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<>
					<DeleteScreen
						handleClose={deleteScreenClose}
						deleteData={deleteReportData}
						data={deleteRow}
						Message={`Are you Sure to Delete  ${deleteRow?.NAME} Report`}
					/>
				</>
			</CustomModal>
		</>
	);
};

export default ReportPage;
