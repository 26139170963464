import axios from 'axios';
import config from '../../../config';

const twoWayCommunicationApi = async ({
	googleIdToken,
	formData,
	resultObject,
}) => {
	try {
		const result = await axios.post(`${config.apiURL}/twoWayFormSubmission`, {
			formData,
			resultObject,
			Authorization: `Bearer ${googleIdToken}`,
		});
		return result;
	} catch (err) {
		return err;
	}
};

const twoWayCommunicationFormApi = async ([googleIdToken, userEmail]) => {
	try {
		const result = await axios.get(`${config.apiURL}/twoWayFormData`, {
			headers: {
				Authorization: `Bearer ${googleIdToken}`,
				userEmail,
			},
		});
		return Array.isArray(result?.data) ? result?.data : [];
	} catch (err) {
		return [];
	}
};

const twoWayCommunicationHomeData = async ({googleIdToken, id, uuid}) => {
	try {
		const result = await axios.get(
			`${config.apiURL}/twoWaySingleProjectionStatusData`,
			{
				headers: {
					Authorization: `Bearer ${googleIdToken}`,
					id,
					uuid,
				},
			}
		);
		return Array.isArray(result?.data) ? result?.data : [];
	} catch (err) {
		return [];
	}
};

const twoWaySingleProjectionStatusDataUpdate = async ({
	googleIdToken,
	formData,
	uuid,
}) => {
	try {
		const result = await axios.put(
			`${config.apiURL}/twoWaySingleProjectionStatusDataUpdate`,
			{
				Authorization: `Bearer ${googleIdToken}`,
				formData,
				uuid,
			}
		);
		return result;
	} catch (err) {
		return err;
	}
};
export const twoWayFormAPi = twoWayCommunicationFormApi;
export const twoWayHomeData = twoWayCommunicationHomeData;
export const twoWaySingleProjectionUpdate =
	twoWaySingleProjectionStatusDataUpdate;
export default twoWayCommunicationApi;
