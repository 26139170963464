import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useOutletContext} from 'react-router-dom';
import Form from '../../components/Form/TwoWayCommunicationForm';
import {setTwoWayFormData} from '../../redux/formReducer';
import {twoWayFormAPi} from '../../components/Utilities/apis/twoWayCommunicationApi';

const TwoWayCommunication = ({handleClose}) => {
	const user = useSelector((state) => state.PowerBi.user);
	const dispatch = useDispatch();
	const googleIdToken = useOutletContext(); // <-- access context value

	const [userForm, setUserForm] = useState({});

	useEffect(() => {
		if (Object.keys(user).length !== 0) {
			setUserForm(user);
			twoWayFormAPi([googleIdToken, user?.EMAILID]).then((result) => {
				dispatch(setTwoWayFormData(result));
			});
		}
	}, [dispatch, googleIdToken, user]);
	return (
		<Form
			form={userForm}
			handleClose={handleClose}
		/>
	);
};

export default TwoWayCommunication;
