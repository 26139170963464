import {createSlice} from '@reduxjs/toolkit';

const adminReducer = createSlice({
	name: 'allUsers',
	initialState: {
		usersDetails: [],
		userDetailsByAudience: [],
		audienceList: [],
		audienceReport: [],
		allReports: [],
		allReportGroups: [],
	},
	reducers: {
		getUsers(state, action) {
			state.usersDetails = action.payload;
		},
		getUsersByAudience(state, action) {
			state.userDetailsByAudience = action.payload;
		},
		getAudience(state, action) {
			state.audienceList = action.payload;
		},
		getAudienceReport(state, action) {
			state.audienceReport = action.payload;
		},
		getReportsReducer(state, action) {
			state.allReports = action.payload;
		},
		getAllGroupsReducer(state, action) {
			state.allReportGroups = action.payload;
		},
	},
});

// export const {getUsers} = adminReducer.actions;
export const {
	getUsers,
	getAudienceReport,
	getAudience,
	getReportsReducer,
	getAllGroupsReducer,
	getUsersByAudience,
} = adminReducer.actions;
export default adminReducer.reducer;
