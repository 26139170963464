import {Grid, Button, Typography, Autocomplete} from '@mui/material';
import {TextField} from '@mui/material';
import classes from './AdminForm.module.css';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getAudience} from '../../redux/adminReducer';
import {
	getAllAudience,
	getAudienceReport,
	saveAudience,
} from '../Utilities/apis/audienceApi';
import {getAudienceReport as getAudienceReportAction} from '../../redux/adminReducer';

const AudienceForm = (props) => {
	const {handleClose, formdata, audienceId, handleAlert} = props;
	const dispatch = useDispatch();
	const audienceReport =
		useSelector((state) => state.AdminPageData.audienceReport) || [];
	// setting Local state for form Data
	const [audienceFormData, setFormData] = useState({
		audienceName: formdata.NAME || '',
		DefaultReportName:
			formdata.DEFAULTREPORTID && formdata.DEFAULT_REPORT_NAME && formdata.ID
				? {
						NAME: formdata.DEFAULT_REPORT_NAME,
						DEFAULTREPORTID: formdata.DEFAULTREPORTID,
						reportId: formdata.ID,
				  }
				: null,
	});

	useEffect(() => {
		getAudienceReport(audienceId).then((data) => {
			dispatch(getAudienceReportAction(data || []));
		});
	}, [dispatch, audienceId]);

	// Function to execute ONSubmit
	const onSubmit = async () => {
		// Code To check Empty and Null Values
		const isEmpty = Object.values(audienceFormData).some(
			(x) => x === '' || x === null
		);
		const isReportDataEmpty = audienceFormData.DefaultReportName
			? Object.values(audienceFormData.DefaultReportName).some(
					(x) => x === '' || x === null
			  )
			: false;
		if (!isEmpty && !isReportDataEmpty) {
			try {
				saveAudience({audienceFormData, audienceId}).then((res) => {
					handleAlert(res);
					getAllAudience().then((data) => {
						dispatch(getAudience(data));
					});
					handleClose();
				});
			} catch (err) {
				console.log(err);
			}
		}
	};

	const handleNameChange = (e) => {
		const val = e.target.value;
		// Code for Excluding Numeric and Special Characters
		if (val.match(/[^A-Za-z]/)) {
			return e.preventDefault();
		}
		setFormData(() => ({
			...audienceFormData,
			[e.target.name]: val,
		}));
	};

	// Functio to Handle Email Change
	const handleDefaultReportChange = (e, value) => {
		setFormData(() => ({
			...audienceFormData,
			DefaultReportName: {
				NAME: value ? value.NAME : null,
				DEFAULTREPORTID: value ? value.PBIREPORTID : null,
				reportId: value ? value.ID : null,
			},
		}));
	};

	return (
		<Grid
			container
			item
			xs={6}
			md={6}
			xl={6}
			className={classes.formContainer}
		>
			<Grid
				container
				className={classes.headingContainer}
			>
				<Grid
					item
					xs={6}
					md={6}
					xl={6}
					sx={{
						display: 'flex',
						justifyContent: 'left',
					}}
				>
					<Typography className={classes.header}>Add Audience</Typography>
				</Grid>
			</Grid>
			<Grid
				container
				className={classes.textFieldContainer}
			>
				<Grid
					item
					xs={6}
					md={6}
					xl={6}
					className={classes.nameField}
				>
					<TextField
						label='Name'
						variant='standard'
						name='audienceName'
						autoComplete='off'
						value={audienceFormData.audienceName}
						onChange={handleNameChange}
						className={classes.autoComplete}
					/>
				</Grid>
				<Grid
					xs={6}
					md={6}
					xl={6}
					item
					className={classes.emailField}
				>
					<Autocomplete
						renderOption={(props, option) => (
							<Typography
								{...props}
								style={{fontSize: '12px'}}
								key={option.ID}
							>
								{`${option?.NAME} - ${option?.GroupName}`}
							</Typography>
						)}
						options={audienceReport}
						className={classes.autoComplete}
						value={audienceFormData.DefaultReportName}
						autoHighlight
						id='DefaultReportName'
						isOptionEqualToValue={(option, value) =>
							option.NAME === value.NAME || value === ''
						}
						getOptionLabel={(option) => (option.NAME ? option.NAME : '')}
						renderInput={(params) => (
							<TextField
								{...params}
								label='DefaultReportName'
								variant='standard'
							/>
						)}
						onChange={(e, value) => handleDefaultReportChange(e, value)}
					/>
				</Grid>
			</Grid>
			<Grid
				container
				className={classes.buttonContainer}
			>
				<Button
					// className={classes.clearButton}
					onClick={handleClose}
					style={{
						width: '120px',
						height: '32px',
						color: '#F7901D',
					}}
				>
					Cancel
				</Button>
				<Button
					type='button'
					className={classes.submitButton}
					onClick={onSubmit}
					style={{
						width: '120px',
						height: '32px',
						backgroundColor: '#F7901D',
						color: 'white',
					}}
				>
					Submit
				</Button>
			</Grid>
		</Grid>
	);
};

export default AudienceForm;
