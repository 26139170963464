import './App.css';
import Glossary from './components/Glossary/Glossary';
import HomePage from './pages/Home/HomePage';
import Login from './pages/Login/Login';
import {Routes, Route, BrowserRouter} from 'react-router-dom';
import PrivateRoutes from './components/Utilities/PrivateRoutes';
import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {
	ReactPlugin,
	withAITracking,
} from '@microsoft/applicationinsights-react-js';
import TwoWayCommunication from './pages/TwoWayCommunication/TwoWayCommunication';
import ReportPage from './pages/Reports/ReportPage';
import {useSelector} from 'react-redux';
import TwoWayHome from './pages/TwoWayFormResponse/TwoWayFormResponse';
import ProjectionsRemarks from './pages/Remarks/ProjectionsRemarks';
import AdminNew from './pages/Admin/AdminNew';
import PageNotFound from './pages/PageNotFound/PageNotFound';

function App() {
	let user = useSelector((state) => state.PowerBi.user);
	var reactPlugin = new ReactPlugin();
	var appInsights = new ApplicationInsights({
		config: {
			instrumentationKey: '9d5e64f0-ccad-44d2-a134-083ba92226aa',
			enableAutoRouteTracking: true,
			extensions: [reactPlugin],
		},
	});
	if (
		window.location.origin === 'https://onedata.tigeranalyticstest.in' ||
		window.location.origin.includes('https://onedata.tigeranalyticstest.in')
	) {
		return <PageNotFound />;
	}

	appInsights.loadAppInsights();
	return (
		<div className='App'>
			<BrowserRouter>
				<Routes>
					<Route
						exact
						path='/'
						element={<Login />}
					/>
					<Route element={<PrivateRoutes />}>
						<Route
							path='reports'
							element={<HomePage />}
						/>
						{user?.ISADMIN && (
							<Route path='/admin'>
								<Route
									path=''
									element={<AdminNew />}
								/>
								<Route
									path='report'
									element={<ReportPage />}
								/>
							</Route>
						)}
						<Route
							path='/glossary'
							element={<Glossary />}
						/>
						<Route
							path='form/:uuid/:id'
							element={<TwoWayHome />}
						/>
						<Route
							path='/remarks'
							element={<ProjectionsRemarks />}
						/>
					</Route>
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;
