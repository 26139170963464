//  The `Update_reports` function makes an asynchronous GET request using Axios to a specific URL (`http://localhost:4000/getReportDetailsByEmail?`).
// This request is presumably fetching report details based on the provided email address.

import axios from 'axios';
import config from '../../../config';

const getReportsAPI = async (props) => {
	const {googleIdToken, user} = props;
	try {
		const result = await axios.get(
			`${config.apiURL}/getReportDetails?ID=` + user.ID,
			{
				headers: {
					Authorization: `Bearer ${googleIdToken}`,
				},
			}
		);
		if (Object.keys(result.data[0]).length !== 0) {
			return result.data;
		}
		return [];
	} catch (err) {
		return [];
	}
};

const getAudienceReports = async () => {
	const token = localStorage.getItem('googleIdToken');
	try {
		const result = await axios.get(`${config.apiURL}/getAudienceReports?ID=`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		if (Object.keys(result.data.length !== 0)) {
			return result.data;
		}
		return [];
	} catch (err) {
		return [];
	}
};

export default getReportsAPI;
export {getAudienceReports};
