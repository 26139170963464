import axios from 'axios';
import config from '../../../config';

const audienceToReportsMapping = async (props) => {
	const token = localStorage.getItem('googleIdToken');
	try {
		const res = await axios.post(`${config.apiURL}/audienceToReportsMapping`, {
			Authorization: `Bearer ${token}`,
			props,
		});
		return res;
	} catch (err) {
		return err;
	}
};
export default audienceToReportsMapping;
