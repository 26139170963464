import {Box, Grid, Typography} from '@mui/material';
import classes from './Glossary.module.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {getGlossary} from '../../redux/store';
import Search from '../Search/Search';
import Highlighter from 'react-highlight-words';
import CloseIcon from '@mui/icons-material/Close';
import getGlossaryApi from '../Utilities/apis/getGlossaryApi';
const Glossary = (props) => {
	const {handleClose} = props;
	const dispatch = useDispatch();
	const [searchValue, setSearchValue] = useState('');
	const searchFields = ['Category', 'Metrics', 'calculation_logic', 'notes'];
	const rowdata = useSelector((state) => state.glossary.glossaryData)?.filter(
		(row) =>
			searchFields?.some(
				(prop) =>
					row?.[prop] &&
					row?.[prop].toLowerCase().includes(searchValue?.toLowerCase())
			)
	);
	const handleSearch = (event) => {
		event.preventDefault();
		if (event && event.target) {
			setSearchValue(event.target.value);
		}
	};

	const searchReset = () => {
		setSearchValue('');
	};
	useEffect(() => {
		getGlossaryApi().then((data) => {
			dispatch(getGlossary(data));
		});
	}, []);

	return (
		<Box className={classes.glossaryStyle}>
			<Grid
				container
				className={classes.glossaryBody}
			>
				<Grid
					container
					className={classes.firstline}
				>
					<Grid
						item
						xs={8}
						md={8}
						xl={8}
						className={classes.left}
					>
						<Typography className={classes.glossary}>Glossary</Typography>
					</Grid>

					<Grid
						item
						xs={3.5}
						md={3.5}
						xl={3.5}
						className={classes.right}
					>
						<Search
							onSearch={handleSearch}
							onClear={searchReset}
							searchValue={searchValue}
						/>
					</Grid>

					<Grid
						item
						xs={0.5}
						md={0.5}
						xl={0.5}
						className={classes.right}
					>
						<CloseIcon
							className={classes.closeButton}
							onClick={handleClose}
						/>
					</Grid>
				</Grid>
				{rowdata && rowdata?.length > 0 ? (
					<Grid
						item
						xs={12}
						md={12}
						xl={12}
						className={classes.tableContainer}
					>
						<TableContainer
							component={Paper}
							className={classes.table}
							sx={{
								'&::-webkit-scrollbar': {
									width: 6,
								},
								'&::-webkit-scrollbar-track': {
									backgroundColor: '#F4F4F4',
								},
								'&::-webkit-scrollbar-thumb': {
									backgroundColor: '#FD9327',
									borderRadius: 1,
								},
							}}
						>
							<Table aria-label='simple table'>
								<TableHead>
									<TableRow sx={{backgroundColor: '#F7F3EF'}}>
										<TableCell
											className={classes.tableCellHeader}
											align='left'
										>
											Category
										</TableCell>
										<TableCell
											className={classes.tableCellHeader}
											align='left'
										>
											Metrics
										</TableCell>
										<TableCell
											className={classes.tableCellHeader}
											align='left'
										>
											Calculation Logic
										</TableCell>
										<TableCell
											className={classes.tableCellHeader}
											align='left'
										>
											Notes / Comments
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{rowdata.map((row, id) => (
										<TableRow
											key={id}
											sx={{
												'&:last-child td, &:last-child th': {border: 0},
												'&:nth-of-type(odd)': {
													backgroundColor: '#ffffff',
												},
												'&:nth-of-type(even)': {
													backgroundColor: '#ffffff',
												},
											}}
										>
											<TableCell
												component='th'
												scope='row'
												align='left'
												className={classes.tableCell}
											>
												<Highlighter
													highlightStyle={{
														borderRadius: 5,
													}}
													highlightClassName={classes.highlighterText}
													searchWords={[searchValue]}
													autoEscape={true}
													textToHighlight={row?.Category || ''}
												/>
											</TableCell>
											<TableCell
												align='left'
												className={classes.tableCell}
											>
												<Highlighter
													highlightStyle={{
														borderRadius: 5,
													}}
													highlightClassName={classes.highlighterText}
													searchWords={[searchValue]}
													autoEscape={true}
													textToHighlight={row?.Metrics || ''}
												/>
											</TableCell>
											<TableCell
												align='left'
												className={classes.tableCell}
											>
												<Highlighter
													highlightStyle={{
														borderRadius: 5,
													}}
													highlightClassName={classes.highlighterText}
													searchWords={[searchValue]}
													autoEscape={true}
													textToHighlight={row?.calculation_logic || ''}
												/>
											</TableCell>
											<TableCell
												align='left'
												className={classes.tableCell}
											>
												<Highlighter
													highlightClassName={classes.highlighterText}
													highlightStyle={{
														borderRadius: 5,
													}}
													searchWords={[searchValue]}
													autoEscape={true}
													textToHighlight={row?.notes || ''}
												/>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
				) : (
					<Box
						style={{
							height: '510px',
							width: '1140px',
						}}
					>
						<Box
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							No Data
						</Box>
					</Box>
				)}
			</Grid>
		</Box>
	);
};

export default Glossary;
