import {jwtDecode} from 'jwt-decode';

var timeOut = (exp, logoutfunction) => {
	let currentDate = new Date();
	let timeout = setTimeout(
		logoutfunction,
		parseInt(exp * 1000 - currentDate.getTime() - 360000)
	);
	return () => {
		//Clearing a timeout
		clearTimeout(timeout);
	};
};

const checkValidToken = (logoutfunction, token) => {
	let currentDate = new Date();
	try {
		if (token) {
			let decodedToken = jwtDecode(token);
			const time = decodedToken.exp * 1000 > currentDate.getTime();
			timeOut(decodedToken.exp, logoutfunction);
			return time;
		}
		return false;
	} catch (err) {
		return false;
	}
};

const checkvalidEmbedToken = (token) => {
	let currentDate = new Date();
	try {
		if (token) {
			let decodedToken = jwtDecode(token);
			const time = decodedToken.exp * 1000 > currentDate.getTime();
			return time;
		}
		return false;
	} catch (err) {
		return false;
	}
};

var embedReportTimeOut = (exp, refreshFunction) => {
	let currentDate = new Date();
	let timeout = setTimeout(
		refreshFunction,
		parseInt(exp * 1000 - currentDate.getTime() - 300000)
	);
	return () => {
		//Clearing a timeout
		clearTimeout(timeout);
	};
};

const reportEmbedTokenRefresh = (refreshFunction, token) => {
	let currentDate = new Date();
	try {
		if (token) {
			let decodedToken = jwtDecode(token);
			const time = decodedToken.exp * 1000 > currentDate.getTime();
			embedReportTimeOut(decodedToken.exp, refreshFunction);
			return time;
		}
		return false;
	} catch (err) {
		return false;
	}
};
export default checkValidToken;
export {checkvalidEmbedToken, reportEmbedTokenRefresh};
