/**
 * Redux Toolkit Slice for PowerBiOneData
 * This slice manages the state for user data, reports, report access data, and QA access data.
 */
import {createSlice, current} from '@reduxjs/toolkit';

const initialState = {
	user: false,
	reports: [],
	defaultReport: {},
	selectedReport: {},
	reportEmbedUrls: {},
	QAAccessData: [],
	reportEmbedToken: null,
	reportSliceState: {},
	defaultReportPage: {},
};

export const PowerBiOneData = createSlice({
	name: 'PowerBi Onedata',
	initialState,
	reducers: {
		/**
		 * Reducer to add or update the user data in the state.
		 * @param {Object} state - The current state.
		 * @param {Object} action - The action containing the user data.
		 * @returns {Object} - The updated state.
		 */
		addUser: (state, action) => {
			state.user = action.payload;
			return state;
		},
		/**
		 * Reducer to add or update the reports data in the state.
		 * @param {Object} state - The current state.
		 * @param {Object} action - The action containing the reports data.
		 * @returns {Object} - The updated state.
		 */ addReports: (state, action) => {
			state.reports = action.payload;
			return state;
		},
		/**
		 * Reducer to add or update the report access data in the state.
		 * @param {Object} state - The current state.
		 * @param {Object} action - The action containing the report access data.
		 * @returns {Object} - The updated state.
		 */ addReportEmbedUrls: (state, action) => {
			state.reportEmbedUrls = action.payload;
			return state;
		},

		//Reducer to Add the Default Report
		addDefaultReport: (state, action) => {
			state.defaultReport = action.payload;
			return state;
		},

		addSelectedReport: (state, action) => {
			state.selectedReport = action.payload;
			return state;
		},

		addEmbedToken: (state, action) => {
			state.reportEmbedToken = action.payload;
			return state;
		},
		addReportSlice: (state, action) => {
			if (!state.reportSliceState[action.payload[1]]) {
				state.defaultReportPage[action.payload[1]] = action.payload[2];
			}
			if (state.defaultReportPage[action.payload[1]] === action.payload[2]) {
				const result = action.payload[0].map((item) => {
					let selector = {
						$schema: 'http://powerbi.com/product/schema#visualSelector',
						visualName: item[1],
					};
					const state = {
						filters: [item[0][0]],
					};
					return {selector, state};
				});
				state.reportSliceState[action.payload[1]] = result;
				return state;
			}
		},

		clearReportSlice: (state, action) => {
			state.reportSliceState = {};
			return state;
		},
		resetUser: (state, action) => {
			state = initialState;
			return state;
		},

		/**
		 * Reducer to add or update the QA access data in the state.
		 * @param {Object} state - The current state.
		 * @param {Object} action - The action containing the QA access data.
		 * @returns {Object} - The updated state.
		 */ addQAAccessData: (state, action) => {
			state.QAAccessData = action.payload;
			return state;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	addUser,
	addReports,
	addReportEmbedUrls,
	addQAAccessData,
	addDefaultReport,
	addSelectedReport,
	addEmbedToken,
	addReportSlice,
	clearReportSlice,
	resetUser,
} = PowerBiOneData.actions;

export default PowerBiOneData.reducer;
