import {Grid, Button, Typography, Autocomplete} from '@mui/material';
import {TextField} from '@mui/material';
import classes from './AdminForm.module.css';
import {useEffect, useState} from 'react';
import {StyledEngineProvider} from '@mui/material/styles';
import {useDispatch, useSelector} from 'react-redux';
import {getAudiences} from '../../redux/formReducer';
import getAudiencesData from '../Utilities/getAudiencesData';
import addUser from '../Utilities/apis/addUser';
import updateUser from '../Utilities/updateUser';
import {getUsers} from '../../redux/adminReducer';
import getAllUsers from '../../components/Utilities/apis/getAllUsers';

const AdminForm = (props) => {
	const {handleClose, formdata, userId, handleAlert} = props;
	const [emailvalid, setEmailValid] = useState(true);
	const dispatch = useDispatch();
	// setting Local state for form Data
	const [formData, setFormData] = useState({
		FirstName: formdata.FIRSTNAME ? formdata.FIRSTNAME : '',
		EmailID: formdata.EMAILID ? formdata.EMAILID : '',
		LastName: formdata.LASTNAME ? formdata.LASTNAME : null,
		RoleName: formdata.PBI_ROLE ? formdata.PBI_ROLE : null,
		Audience:
			formdata.audienceName && formdata.audienceId
				? {NAME: formdata.audienceName, audienceId: formdata.audienceId}
				: null,
	});
	const AudienceOptions = useSelector((state) => state.Form.AudienceValues);
	// Function to  Validate Email
	const isEmailValid = (email) => {
		var regex = /^[a-zA-Z.0-9-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		if (email.match(regex) && email.split('@')[1] === 'tigeranalytics.com') {
			return true;
		}
	};

	// Function to execute ONSubmit
	const onSubmit = async () => {
		// Code To check Empty and Null Values
		const isEmpty = Object.values(formData).some((x) => x === '' || x === null);
		const isAudienceEmpty = formData.Audience
			? Object.values(formData.Audience).some((x) => x === '' || x === null)
			: false;
		if (!isEmpty && !isAudienceEmpty && isEmailValid(formData.EmailID)) {
			try {
				userId
					? updateUser({formData, userId}).then((res) => {
							handleAlert(res);
							handleClose();
							getAllUsers().then((data) => {
								dispatch(getUsers(data));
							});
					  })
					: addUser(formData).then((res) => {
							handleAlert(res);
							handleClose();
							getAllUsers().then((data) => {
								dispatch(getUsers(data));
							});
					  });
			} catch (err) {
				console.log(err);
			}
		}
	};
	const handleNameChange = (e) => {
		const val = e.target.value;
		// Code for Excluding Numeric and Special Characters
		if (val.match(/[^A-Za-z]/)) {
			return e.preventDefault();
		}
		setFormData(() => ({
			...formData,
			[e.target.name]: val,
		}));
	};

	// Functio to Handle Email Change
	const handleEmailChange = (e) => {
		const val = e.target.value;
		isEmailValid(val) ? setEmailValid(true) : setEmailValid(false);
		setFormData(() => ({
			...formData,
			[e.target.name]: val,
		}));
	};

	// Function For ONSelect Role
	const selectRole = (e, value) => {
		setFormData(() => ({
			...formData,
			RoleName: value,
		}));
	};

	// Function For OnSelect Audience
	const selectAudience = (e, value) => {
		setFormData(() => ({
			...formData,
			Audience: {
				NAME: value ? value.NAME : null,
				audienceId: value ? value.ID : null,
			},
		}));
	};

	useEffect(() => {
		getAudiencesData().then((result) => dispatch(getAudiences(result)));
	}, []);

	return (
		<StyledEngineProvider injectFirst>
			<Grid
				container
				item
				xs={6}
				md={6}
				xl={6}
				className={classes.formContainer}
			>
				<Grid
					container
					className={classes.headingContainer}
				>
					<Grid
						item
						xs={6}
						md={6}
						xl={6}
						sx={{
							display: 'flex',
							justifyContent: 'left',
						}}
					>
						<Typography className={classes.header}>Add User</Typography>
					</Grid>
				</Grid>
				<Grid
					container
					className={classes.textFieldContainer}
				>
					<Grid
						item
						xs={6}
						md={6}
						xl={6}
						className={classes.nameField}
					>
						<TextField
							label='FirstName'
							variant='outlined'
							name='FirstName'
							autoComplete='off'
							value={formData.FirstName ? formData.FirstName : ''}
							onChange={handleNameChange}
							size='small'
							className={classes.autoComplete}
						/>
					</Grid>
					<Grid
						xs={6}
						md={6}
						xl={6}
						item
						className={classes.nameField}
					>
						<TextField
							label='LastName'
							variant='outlined'
							size='small'
							name='LastName'
							autoComplete='off'
							value={formData.LastName ? formData.LastName : ''}
							onChange={handleNameChange}
							className={classes.autoComplete}
						/>
					</Grid>
				</Grid>
				<Grid
					container
					className={classes.textFieldContainer}
				>
					<Grid
						item
						xs={6}
						md={6}
						xl={6}
						className={classes.nameField}
					>
						<TextField
							error={emailvalid ? false : true}
							helperText={emailvalid ? '' : 'Enter a valid email'}
							label='Email'
							variant='outlined'
							size='small'
							name='EmailID'
							autoComplete='off'
							value={formData.EmailID ? formData.EmailID : ''}
							onChange={handleEmailChange}
							className={classes.autoComplete}
						/>
					</Grid>
					<Grid
						xs={6}
						md={6}
						xl={6}
						item
						className={classes.emailField}
					>
						<Autocomplete
							renderOption={(props, option) => (
								<Typography
									{...props}
									style={{fontSize: '12px'}}
								>
									{option}
								</Typography>
							)}
							options={[
								'Practice_Users',
								'Master_Users',
								'CP L1_Users',
								'CP L2_Users',
								'DP_Users',
								'TP_Users',
								'BU_Users',
								'FINOPS_Users',
							]}
							id='RoleName'
							autoHighlight
							value={formData.RoleName}
							className={classes.autoComplete}
							renderInput={(params) => (
								<TextField
									{...params}
									label='Role Group'
									variant='outlined'
									size='small'
									inputProps={{...params.inputProps, style: {fontSize: '18px'}}}
								/>
							)}
							onChange={(e, value) => selectRole(e, value)}
						/>
					</Grid>
				</Grid>
				<Grid
					container
					className={classes.textFieldContainer}
				>
					<Grid
						xs={6}
						md={6}
						xl={6}
						item
						className={classes.emailField}
					>
						<Autocomplete
							renderOption={(props, option) => (
								<Typography
									{...props}
									style={{fontSize: '12px'}}
									key={option.ID}
								>
									{option.NAME}
								</Typography>
							)}
							options={AudienceOptions}
							isOptionEqualToValue={(option, value) =>
								option.NAME === value.NAME || value === ''
							}
							getOptionLabel={(option) => (option.NAME ? option.NAME : '')}
							id='Audience'
							autoHighlight
							value={formData.Audience}
							className={classes.autoComplete}
							renderInput={(params) => (
								<TextField
									{...params}
									label='Audience'
									variant='outlined'
									size='small'
								/>
							)}
							onChange={(e, value) => selectAudience(e, value)}
						/>
					</Grid>
				</Grid>

				<Grid
					container
					className={classes.buttonContainer}
				>
					<Button
						// className={classes.clearButton}
						onClick={handleClose}
						style={{
							width: '120px',
							height: '32px',
							color: '#F7901D',
						}}
					>
						Cancel
					</Button>
					<Button
						// className={classes.submitButton}
						onClick={onSubmit}
						style={{
							// border: '1px solid #1c7eee',
							width: '120px',
							height: '32px',
							backgroundColor: '#F7901D',
							color: 'white',
						}}
					>
						Submit
					</Button>
				</Grid>
			</Grid>
		</StyledEngineProvider>
	);
};

export default AdminForm;
