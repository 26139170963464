import {Grid, Typography} from '@mui/material';
import classes from './Report.module.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import {useState} from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {getAudienceReport as getAudienceReportAction} from '../../redux/adminReducer';
import {getAudienceReport} from '../../components/Utilities/apis/audienceApi';
import {deleteReportToAudience} from '../../components/Utilities/apis/deleteApis';
import MultipleSelectComponent from '../MultiSelectComponent/MultiSelectComponent';
import CustomAlert from '../../components/Alerts/CustomAlert';
import DeleteScreen from '../DeleteDialog/DeleteScreen';
import CustomModal from '../Modal/CustomModal';

const ReportPopup = (props) => {
	const dispatch = useDispatch();
	const {handleClose, audienceName, audienceId} = props;
	const [submitOpen, setSubmitOpen] = useState(false);
	const [alert, setAlert] = useState(false);
	const [alertmessage, setAlertMessage] = useState(null);
	const [success, setSuccess] = useState(false);
	const [deleteScreenOpen, setDeleteScreenOpen] = useState(false);
	const [deleteRow, setDeleteRow] = useState(null);
	const audienceReport = useSelector(
		(state) => state.AdminPageData.audienceReport
	);
	const handleSubmitOpen = () => {
		setSubmitOpen(!submitOpen);
	};

	const handleAlert = (res) => {
		if (res.status === 200) {
			setAlertMessage(res.data.message);
			setAlert(true);
			setSuccess(true);
		} else {
			setAlertMessage(res.response.data.message);
			setAlert(true);
			setSuccess(false);
		}
	};

	const handleAlertClose = () => {
		setAlert(false);
	};

	const deleteScreenClose = () => {
		setDeleteScreenOpen(false);
		setDeleteRow(null);
	};

	const handleDelete = (row) => {
		setDeleteRow(row);
		setDeleteScreenOpen(true);
	};

	const deleteReport = (row) => {
		deleteReportToAudience({...row, audienceId}).then((res) => {
			handleAlert(res);
			getAudienceReport(audienceId).then((data) => {
				dispatch(getAudienceReportAction(data));
			});
			deleteScreenClose();
		});
	};

	return (
		<Grid
			container
			item
			xs={8}
			md={8}
			xl={8}
			className={classes.reportModal}
			// style={{maxHeight: '100%', overflowY: 'auto'}}
		>
			<Grid
				container
				className={classes.firstline}
			>
				<CustomModal
					open={deleteScreenOpen}
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<>
						<DeleteScreen
							handleClose={deleteScreenClose}
							deleteData={deleteReport}
							data={deleteRow}
							Message={`Are you Sure to Delete  ${deleteRow?.NAME} Report`}
						/>
					</>
				</CustomModal>
				<Grid
					item
					xs={6}
					md={6}
					xl={6}
					className={classes.left}
				>
					<Typography style={{fontSize: '14px', fontWeight: 'bold'}}>
						Reports
					</Typography>
					<CustomAlert
						alert={alert}
						message={alertmessage}
						handleClose={handleAlertClose}
						success={success}
					/>
				</Grid>
				<Grid
					item
					xs={6}
					md={6}
					xl={6}
					className={classes.right}
				>
					<CloseIcon
						className={classes.closeIcon}
						onClick={handleClose}
					/>
				</Grid>
				<Grid
					container
					className={classes.secondline}
				>
					<Grid
						item
						xs={12}
						md={12}
						xl={12}
						className={classes.right}
					>
						{submitOpen && (
							<MultipleSelectComponent
								audienceReport={audienceReport}
								audienceId={audienceId}
								handleSubmitOpen={handleSubmitOpen}
								handleAlert={handleAlert}
							/>
						)}
						{!submitOpen && (
							<Typography
								className={classes.addUserButton}
								onClick={handleSubmitOpen}
							>
								Add Reports for {audienceName} Audience
							</Typography>
						)}
					</Grid>
				</Grid>
			</Grid>
			<Grid container>
				<TableContainer
					component={Paper}
					className={classes.table}
					sx={{
						'&::-webkit-scrollbar': {
							width: 6,
						},
						'&::-webkit-scrollbar-track': {
							backgroundColor: '#F4F4F4',
						},
						'&::-webkit-scrollbar-thumb': {
							backgroundColor: '#ccc',
							borderRadius: 2,
						},
					}}
					style={{minWidth: 650, maxHeight: 320, boxShadow: 'none'}}
				>
					<Table aria-label='simple table'>
						<TableHead>
							<TableRow
								sx={{
									backgroundColor: '#f1f1f1',
									fontSize: '14px',
									color: '#312E2D',
									fontWeight: 'bold',
								}}
							>
								<TableCell
									sx={{fontWeight: 'bold'}}
									align='center'
								>
									S.No
								</TableCell>
								<TableCell
									sx={{fontWeight: 'bold'}}
									align='left'
								>
									Report Name
								</TableCell>
								<TableCell
									sx={{fontWeight: 'bold'}}
									align='left'
								>
									Date Created
								</TableCell>
								<TableCell
									sx={{fontWeight: 'bold'}}
									align='center'
								>
									Delete
								</TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{audienceReport?.map((row, id) => (
								<TableRow
									key={id}
									sx={{
										'&:last-child td, &:last-child th': {border: 0},
										'&:nth-of-type(odd)': {
											backgroundColor: 'white',
										},
										'&:nth-of-type(even)': {
											backgroundColor: 'white',
										},
									}}
								>
									<TableCell align='right'>{id + 1}</TableCell>
									<TableCell
										component='th'
										scope='row'
										align='left'
									>
										{row?.NAME} - {row?.GroupName}
									</TableCell>
									<TableCell align='left'>
										{moment(row.CREATEDAT).format('YYYY-MM-DD HH:mm:ss')}
									</TableCell>
									<TableCell align='center'>
										<DeleteOutlineIcon
											className={classes.deleteIcon}
											onClick={() => handleDelete(row)}
										/>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
		</Grid>
	);
};

export default ReportPopup;
