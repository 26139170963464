import classes from './DeleteScreen.module.css';
import {StyledEngineProvider} from '@mui/material/styles';
import {Grid, Button, Box, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const DeleteScreen = ({handleClose, deleteData, data, Message}) => {
	return (
		<StyledEngineProvider injectFirst>
			<Grid
				container
				className={classes.body}
				xs={4}
				md={4}
				xl={4}
			>
				<Box>
					<CloseIcon
						className={classes.closeIcon}
						style={{
							height: (window.screen.height * 10) / 100,
							width: (window.screen.width * 6) / 100,
						}}
					/>
					<Typography className={classes.message}>{`${Message} ?`}</Typography>
					<Typography className={classes.warning}>
						This Process Cannot be Undone
					</Typography>
					<Grid
						container
						className={classes.buttonContainer}
					>
						<Grid
							item
							xs={12}
							md={12}
							xl={12}
							className={classes.center}
						>
							<Button
								onClick={handleClose}
								className={classes.closeButton}
								variant='contained'
							>
								Cancel
							</Button>
							<Button
								onClick={() => deleteData(data)}
								className={classes.button}
								variant='contained'
							>
								Delete
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Grid>
		</StyledEngineProvider>
	);
};

export default DeleteScreen;
