import axios from 'axios';
import config from '../../../config';
const refreshToken = async () => {
	const refreshtoken = localStorage.getItem('refreshToken');

	try {
		const result = await axios.post(
			`${config.apiURL}/auth/google/refresh-token`,
			{
				code: refreshtoken,
			}
		);
		if (Object.keys(result.data).length !== 0) {
			return result.data.id_token;
		}
		return null;
	} catch (err) {
		return null;
	}
};

export default refreshToken;
