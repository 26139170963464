import {StyledEngineProvider} from '@mui/material/styles';
import classes from './CustomTable.module.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {Grid, Box, Tooltip, Typography} from '@mui/material';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CustomModal from '../../../components/Modal/CustomModal';
import HomeIcon from '@mui/icons-material/Home';
import {useNavigate} from 'react-router-dom';
import TwoWayHomeForm from '../../../components/Form/TwoWayCommunicationHomeResponseForm';
import {useState} from 'react';
import moment from 'moment';

const CustomTable = ({data: rowdata, getData}) => {
	const [open, setOpen] = useState(false);
	const [hoverRemarks, sethoverRemarks] = useState(false);
	const Navigate = useNavigate();
	const [formData, setFormData] = useState([]);
	const [uuid, setUUID] = useState(null);

	const handleClose = () => {
		setOpen(false);
	};
	const updateRow = (row) => {
		setFormData([row]);
		setUUID(row?.UUID);
		setOpen(true);
	};
	return (
		<StyledEngineProvider injectFirst>
			<CustomModal
				open={open}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<>
					<TwoWayHomeForm
						homeData={formData}
						uuid={uuid}
						getData={getData}
						handleClose={handleClose}
					/>
				</>
			</CustomModal>
			{rowdata && rowdata?.length > 0 && (
				<Grid
					container
					className={classes.Heading}
				>
					<Grid
						item
						className={classes.leftHeader}
						xs={6}
						md={6}
						xl={6}
					>
						<Grid
							item
							xs={2}
							md={2}
							xl={2}
							className={classes.homeIconContainer}
						>
							<span
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<Tooltip title={'Click here for ReportsPage'}>
									<HomeIcon
										className={classes.text}
										onClick={() => Navigate('/reports')}
										style={{cursor: 'pointer'}}
									/>
								</Tooltip>
							</span>
						</Grid>
					</Grid>
					<Grid
						item
						md={6}
						xs={6}
						xl={6}
						className={classes.rightHeader}
					>
						<Box
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-around',
							}}
						>
							<span className={classes.text}>Total Requests</span>
							<span className={classes.text}>{rowdata?.length}</span>
						</Box>
					</Grid>
				</Grid>
			)}
			<TableContainer
				component={Paper}
				className={classes.table}
				sx={{
					'&::-webkit-scrollbar': {
						width: 3,
						height: 3,
					},
					'&::-webkit-scrollbar-track': {
						backgroundColor: '#F4F4F4',
						cursor: 'pointer',
					},
					'&::-webkit-scrollbar-thumb': {
						backgroundColor: 'rgb(37, 200, 241)',
						cursor: 'pointer',
					},
				}}
				style={{
					maxWidth: (window.screen.width * 99) / 100,
					maxHeight: (window.screen.height * 64) / 100,
				}}
			>
				<Table aria-label='simple table'>
					<TableHead sx={{position: 'sticky', top: 0, zIndex: 1}}>
						<TableRow
							sx={{
								backgroundColor: 'rgb(37, 200, 241)',
								fontSize: '14px',
								color: '#312E2D',
								fontWeight: 'bold',
								border: '2px solid rgb(37, 200, 241)',
							}}
						>
							<TableCell
								sx={{fontWeight: 'bold', padding: '0px', minWidth: '120px'}}
								align='center'
							>
								ID
							</TableCell>
							<TableCell
								sx={{fontWeight: 'bold', padding: '0px', minWidth: '120px'}}
							>
								CUSTOMER_GROUP
							</TableCell>
							<TableCell
								sx={{fontWeight: 'bold', padding: '0px', minWidth: '120px'}}
								align='center'
							>
								CP
							</TableCell>
							<TableCell
								sx={{fontWeight: 'bold', padding: '0px', minWidth: '120px'}}
								align='center'
							>
								BU
							</TableCell>
							<TableCell
								sx={{fontWeight: 'bold', padding: '0px', minWidth: '120px'}}
								align='center'
							>
								DP
							</TableCell>
							<TableRow>
								<TableRow
									sx={{fontWeight: 'bold', padding: '0px'}}
									align='center'
								>
									<TableCell
										sx={{
											fontWeight: 'bold',
											padding: '0px',
											minWidth: '120px',
										}}
										align='center'
										colSpan={4}
									>
										NETADD
									</TableCell>
								</TableRow>
								<TableRow
									sx={{fontWeight: 'bold', padding: '0px'}}
									align='center'
								>
									<TableCell
										sx={{
											fontWeight: 'bold',
											padding: '0px',
											minWidth: '80px',
										}}
										align='center'
									>
										CP
									</TableCell>
									<TableCell
										sx={{
											fontWeight: 'bold',
											padding: '0px',
											minWidth: '80px',
										}}
										align='center'
									>
										DP
									</TableCell>
									<TableCell
										sx={{
											fontWeight: 'bold',
											padding: '0px',
											minWidth: '80px',
										}}
										align='center'
									>
										DOSSIER
									</TableCell>
									<TableCell
										sx={{
											fontWeight: 'bold',
											padding: '0px',
											minWidth: '120px',
										}}
										align='center'
									>
										DOSSIER_CORRECTION
									</TableCell>
								</TableRow>
							</TableRow>
							<TableCell
								sx={{fontWeight: 'bold', padding: '0px', minWidth: '120px'}}
								align='center'
							>
								REMARKS
							</TableCell>
							<TableCell
								sx={{fontWeight: 'bold', padding: '0px', minWidth: '120px'}}
								align='center'
							>
								STATUS
							</TableCell>
							<TableCell
								sx={{fontWeight: 'bold', padding: '0px', minWidth: '120px'}}
								align='center'
							>
								CREATED_AT
							</TableCell>
							<TableCell
								sx={{fontWeight: 'bold', padding: '0px', minWidth: '120px'}}
								align='center'
							>
								CREATED_BY
							</TableCell>
							<TableCell
								sx={{fontWeight: 'bold', padding: '0px', minWidth: '120px'}}
								align='center'
							>
								VIEW-MORE
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rowdata &&
							rowdata?.length > 0 &&
							rowdata?.map((row, id) => (
								<TableRow
									key={row?.DP_CP_PROJECTIONS_TRACKER_KEY}
									sx={{
										'&:last-child td, &:last-child th': {border: 0},
										'&:nth-of-type(even)': {
											backgroundColor: 'white',
										},
										'&:nth-of-type(odd)': {
											backgroundColor: 'rgb(200, 237, 246)',
										},
									}}
								>
									<TableCell
										align='center'
										sx={{
											padding: '0px',
											minWidth: '120px',
										}}
									>
										{id + 1}
									</TableCell>
									<TableCell
										scope='row'
										align='center'
										sx={{padding: '0px', minWidth: '120px'}}
									>
										{row?.CUSTOMER_GROUP}
									</TableCell>

									<TableCell
										align='center'
										sx={{padding: '0px', minWidth: '120px'}}
									>
										{row?.CP}
									</TableCell>
									<TableCell
										align='center'
										sx={{padding: '0px', minWidth: '120px'}}
									>
										{row?.BU}
									</TableCell>
									<TableCell
										align='center'
										sx={{padding: '0px', minWidth: '120px'}}
									>
										{row?.DP}
									</TableCell>
									<TableRow
										scope='row'
										align='center'
										sx={{padding: '0px'}}
									>
										<TableCell
											scope='row'
											align='center'
											sx={{
												fontWeight: 'bold',
												padding: '0px',
												minWidth: '80px',
											}}
										>
											{row?.NETADD_CP}
										</TableCell>
										<TableCell
											scope='row'
											align='center'
											sx={{
												fontWeight: 'bold',
												padding: '0px',
												minWidth: '80px',
											}}
										>
											{row?.NETADD_DP}
										</TableCell>
										<TableCell
											scope='row'
											align='center'
											sx={{
												fontWeight: 'bold',
												padding: '0px',
												minWidth: '80px',
											}}
										>
											{row?.NETADD_DOSSIER}
										</TableCell>
										<TableCell
											scope='row'
											align='center'
											sx={{
												fontWeight: 'bold',
												padding: '0px',
												minWidth: '120px',
											}}
										>
											{row?.NETADD_CORRECTION}
										</TableCell>
									</TableRow>
									<TableCell
										scope='row'
										align='center'
										sx={{padding: '0px', minWidth: '120px'}}
										onMouseLeave={() => sethoverRemarks(false)}
									>
										{hoverRemarks === row?.DP_CP_PROJECTIONS_TRACKER_KEY ? (
											<Typography className={classes.remarksText}>
												{row?.REMARKS}
											</Typography>
										) : (
											<VisibilityIcon
												onMouseEnter={() =>
													sethoverRemarks(row?.DP_CP_PROJECTIONS_TRACKER_KEY)
												}
												className={classes.viewIcon}
											/>
										)}
									</TableCell>
									<TableCell
										scope='row'
										align='center'
										sx={{
											padding: '0px',
											minWidth: '120px',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											textAlign: 'center',
										}}
									>
										{!row?.STATUS_UPDATED ? (
											<EditIcon
												className={classes.editIcon}
												onClick={() => updateRow(row)}
											/>
										) : (
											'Completed'
										)}
									</TableCell>
									<TableCell
										scope='row'
										align='center'
										sx={{padding: '0px', minWidth: '120px'}}
									>
										{row?.CREATED_AT
											? moment(row?.CREATED_AT).format('DD-MM-YYYY')
											: ''}
									</TableCell>
									<TableCell
										scope='row'
										align='center'
										sx={{padding: '0px', minWidth: '120px'}}
									>
										{row?.CREATED_BY}
									</TableCell>
									<TableCell
										scope='row'
										align='center'
										sx={{padding: '0px', minWidth: '80px'}}
									>
										<ArrowForwardIosIcon
											className={classes.viewIcon}
											onClick={() => updateRow(row)}
										/>
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
		</StyledEngineProvider>
	);
};

export default CustomTable;
