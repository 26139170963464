import React, {useState, useEffect} from 'react';
import {Grid, Box, Typography} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import classes from './AudienceDetails.module.css';
import AudienceForm from '../../components/Form/AudienceForm';
import CustomModal from '../../components/Modal/CustomModal';
import ReportPopup from '../../components/Partial/Report';
import {
	getAudience,
	getAudienceReport as getAudienceReportAction,
	getUsersByAudience,
} from '../../redux/adminReducer';
import {
	getAllAudience,
	getAudienceReport,
} from '../../components/Utilities/apis/audienceApi';
import {getAllUsersByAudienceId} from '../../components/Utilities/apis/getAllUsers';
import {deleteAudience} from '../../components/Utilities/apis/deleteApis';
import AudienceTable from './AudienceTable';
import CustomAlert from '../../components/Alerts/CustomAlert';
import SpatialAudioOffIcon from '@mui/icons-material/SpatialAudioOff';
import DeleteScreen from '../../components/DeleteDialog/DeleteScreen';
import UsersPopUp from './UsersPopUp';

const AudienceDetails = () => {
	const [open, setOpen] = useState(false);
	const [isReportPopupOpen, setIsReportPopupOpen] = useState(false);
	const [isUsersPopupOpen, setIsUsersPopupOpen] = useState(false);
	const [deleteScreenOpen, setDeleteScreenOpen] = useState(false);
	const [deleteRow, setDeleteRow] = useState(null);
	const dispatch = useDispatch();
	const [formdata, setFormdata] = useState({});
	const [audienceId, setaudienceId] = useState(null);
	const [audienceName, setAudienceName] = useState(null);
	const [alert, setAlert] = useState(false);
	const [alertmessage, setAlertMessage] = useState(null);
	const [success, setSuccess] = useState(false);
	const rowdata = useSelector((state) => state.AdminPageData.audienceList);

	const handleAlert = (res) => {
		if (res.status === 200) {
			setAlertMessage(res.data.message);
			setAlert(true);
			setSuccess(true);
		} else {
			setAlertMessage(res.response.data.message);
			setAlert(true);
			setSuccess(false);
		}
	};

	const deleteScreenClose = () => {
		setDeleteScreenOpen(false);
		setDeleteRow(null);
	};

	const handleDelete = (row) => {
		setDeleteRow(row);
		setDeleteScreenOpen(true);
	};

	const handleAlertClose = () => {
		setAlert(false);
	};

	const updateAndAddAudience = (row) => {
		setFormdata(row);
		setaudienceId(row?.ID);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setFormdata({});
		setaudienceId(null);
		setAudienceName(null);
	};

	const openReportPopup = (item) => {
		if (item?.ID) {
			getAudienceReport(item.ID).then((data) => {
				dispatch(getAudienceReportAction(data));
			});
			setAudienceName(item.NAME);
			setaudienceId(item.ID);
			setIsReportPopupOpen(true);
		}
	};

	const handleReportClose = () => {
		setIsReportPopupOpen(false);
	};

	const openUserPopUp = (item) => {
		if (item?.ID) {
			getAllUsersByAudienceId(item?.ID).then((data) => {
				dispatch(getUsersByAudience(data));
			});
			setAudienceName(item?.NAME);
			setaudienceId(item?.ID);
			setIsUsersPopupOpen(true);
		}
	};

	const handleUserPopUpClose = () => {
		setIsUsersPopupOpen(false);
	};

	const deleteAudienceData = (row) => {
		deleteAudience(row).then((res) => {
			handleAlert(res);
			getAllAudience().then((data) => {
				dispatch(getAudience(data));
			});
			deleteScreenClose();
		});
	};

	//  Execute When Page First Loads to get users data
	useEffect(() => {
		getAllAudience().then((data) => {
			dispatch(getAudience(data));
		});
	}, [dispatch]);

	return (
		<>
			<CustomAlert
				alert={alert}
				message={alertmessage}
				handleClose={handleAlertClose}
				success={success}
			/>
			<Grid
				container
				style={{
					display: 'flex',
					backgroundColor: 'rgb(200, 237, 246)',
					marginBottom: '15px',
				}}
			>
				<Grid
					item
					xs={3}
					md={3}
					xl={3}
					style={{
						display: 'flex',
						justifyContent: 'right',
					}}
				>
					<SpatialAudioOffIcon style={{fontSize: '70px'}} />
				</Grid>
				<Grid
					item
					xs={3}
					md={3}
					xl={3}
					style={{
						display: 'flex',
						justifyContent: 'left',
					}}
				>
					<Box
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-around',
						}}
					>
						<span style={{fontSize: '18px', fontWeight: 'bold'}}>
							No. of Audiences
						</span>
						<span style={{fontSize: '18px', fontWeight: 'bold'}}>
							{rowdata?.length}
						</span>
					</Box>
				</Grid>
				<Grid
					item
					xs={6}
					md={6}
					xl={6}
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Typography
						className={classes.addAudienceButton}
						onClick={updateAndAddAudience}
					>
						+ Add Audience
					</Typography>
				</Grid>
			</Grid>
			<AudienceTable
				editData={updateAndAddAudience}
				deleteData={handleDelete}
				data={rowdata}
				openReportPopup={openReportPopup}
				openUserPopUp={openUserPopUp}
			/>
			<CustomModal
				open={open}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<>
					<AudienceForm
						handleClose={handleClose}
						formdata={formdata}
						audienceId={audienceId}
						handleAlert={handleAlert}
					/>
				</>
			</CustomModal>
			<CustomModal
				open={isReportPopupOpen}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<>
					<ReportPopup
						handleClose={handleReportClose}
						audienceName={audienceName}
						audienceId={audienceId}
					/>
				</>
			</CustomModal>
			<CustomModal
				open={isUsersPopupOpen}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<>
					<UsersPopUp
						handleClose={handleUserPopUpClose}
						audienceName={audienceName}
						audienceId={audienceId}
					/>
				</>
			</CustomModal>
			<CustomModal
				open={deleteScreenOpen}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<>
					<DeleteScreen
						handleClose={deleteScreenClose}
						deleteData={deleteAudienceData}
						data={deleteRow}
						Message={`Are you Sure to Delete  ${deleteRow?.NAME} Audience`}
					/>
				</>
			</CustomModal>
		</>
	);
};

export default AudienceDetails;
