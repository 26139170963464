import React, {useEffect, useState} from 'react';
import axios from 'axios';
import styles from './Login.module.css';
import {useGoogleLogin} from '@react-oauth/google';
import {StyledEngineProvider} from '@mui/material/styles';
import {useDispatch, useSelector} from 'react-redux';
import {
	addSelectedReport,
	addUser,
	clearReportSlice,
} from '../../redux/userReducer';
import {useLocation, useNavigate} from 'react-router-dom';
import {ReactComponent as Onedataicon} from '../../components/Assets/OnedataBlackicon.svg';
import {ReactComponent as TigerLogo} from '../../components/Assets/TA_logo_Black.svg';
import {Divider, Grid, Typography, Button} from '@mui/material';
import config from '../../config';
import CircularProgress from '@mui/material/CircularProgress';
import loginApi from '../../components/Utilities/apis/loginApi';
import {checkvalidEmbedToken} from '../../components/Utilities/checkValidToken';
import {ReactComponent as GoogleLogo} from '../../components/Assets/google-logo.svg';

/**
 * Login Component
 * This component represents the login page of the application.
 * It allows users to sign in with Google OAuth and handles user validation.
 * If the user is validated, it stores user information and redirects to the reports page.
 */
const Login = ({location}) => {
	const dispatch = useDispatch();
	const {state} = useLocation();
	const [isValidUser, setIsValidUser] = useState(false);
	const [Location, setLocation] = useState(null);
	const [loader, setLoader] = useState(false);
	const user = useSelector((state) => state.PowerBi.user);
	const navigate = useNavigate();

	useEffect(() => {
		if (state && state.location.pathname) {
			setLocation(state.location.pathname);
		} else {
			setLocation(location);
		}
	}, [Location, state, location]);

	useEffect(() => {
		if (
			Object.keys(user).length !== 0 &&
			localStorage.getItem('refreshToken')
		) {
			setLoader(true);
			// loginActivity([user.ID, 1]);
			navigate('/reports');
		}
	}, [navigate, user]);

	// The OAuthHandler is initialized using the useGoogleLogin hook. This hook is typically set up to provide OAuth authentication capabilities, including handling user login via Google.
	// The OAuthHandler function is responsible for handling the OAuth2 authentication flow with Google.
	// It retrieves user data from Google, validates the user on your server,
	// and takes appropriate actions based on the validation result, including storing user data in the Redux store and navigating the user to the reports page.Authorization,
	// or displaying an error message if needed.]
	// Then when the user logs in you redirect them back with
	const login = useGoogleLogin({
		onSuccess: async (codeResponse) => {
			const code = codeResponse.code;
			setLoader(true);
			const tokens = await axios.post(`${config.apiURL}/auth/google`, {
				code,
			});
			localStorage.setItem('googleIdToken', tokens.data.id_token);
			localStorage.setItem('refreshToken', tokens.data.refresh_token);
			dispatch(addSelectedReport({}));
			dispatch(clearReportSlice());
			await loginApi(tokens.data.id_token).then((result) => {
				dispatch(addUser(result));
			});
			if (Object.keys(user).length !== 0) {
				if (!Location) {
					navigate('/reports');
				} else {
					navigate(Location);
				}
			} else {
				setLoader(false);
				setIsValidUser(true);
			}
		},
		flow: 'auth-code',
		prompt: 'consent',
	});

	return (
		<StyledEngineProvider injectFirst>
			<div className={styles.app}>
				{loader ? (
					<>
						<CircularProgress style={{color: '#f7901d'}} />
						<Typography
							style={{
								color: 'white',
							}}
						>
							Checking Access
						</Typography>
					</>
				) : (
					<>
						{isValidUser ? (
							<p className={styles.errMessage}>Forbidden: Access Denied.!</p>
						) : (
							''
						)}
						<Grid
							container
							className={styles.loginForm}
						>
							<Grid
								item
								md={12}
								className={styles.form}
							>
								<Onedataicon className={styles.onedataicon} />
							</Grid>
							<Grid
								item
								md={12}
								className={styles.signInBox}
							>
								<Grid
									item
									md={12}
									className={styles.signInBox}
								>
									<Button
										className={styles.signInButton}
										onClick={login}
									>
										<GoogleLogo className={styles.googleLogo} />
										<span className={styles.buttonText}>
											Sign In with Google
										</span>
									</Button>
								</Grid>
								{/* <Divider style={{width: '100%', marginBottom: '20px'}} /> */}
							</Grid>
							<Divider style={{width: '100%', marginBottom: '20px'}} />
							<Grid
								container
								className={styles.footContainer}
							>
								<Grid
									item
									md={5}
								>
									<a
										href='https://www.tigeranalytics.com'
										target='_blank'
										rel='noreferrer'
									>
										<TigerLogo className={styles.tigerLogo} />
									</a>
								</Grid>
								<Grid
									item
									md={7}
									className={styles.copyRight}
								>
									Copyrights 2023 | All Rights Reserved
								</Grid>
							</Grid>
						</Grid>
					</>
				)}
			</div>
		</StyledEngineProvider>
	);
};

export default Login;
