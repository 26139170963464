/**
 * Redux Store Configuration
 * This module configures the Redux store for the application,
 * including middleware and state persistence.
 */

import {configureStore} from '@reduxjs/toolkit';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import UserReducer from './userReducer';
import adminReducer from './adminReducer';
import glossaryReducer from './glossaryReducer';
import {getGlossary} from './glossaryReducer';
import formReducer from './formReducer';
// Configuration for Redux state persistence.
const persistConfig = {
	key: 'root',
	storage,
};
// Create a persisted reducer with the user reducer
const persistedReducer = persistReducer(persistConfig, UserReducer);

// Configure the Redux store with the persisted reducer and middleware
export const store = configureStore({
	reducer: {
		PowerBi: persistedReducer,
		AdminPageData: adminReducer,
		glossary: glossaryReducer,
		Form: formReducer,
	},
	// Middleware configuration
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});
// setupListeners(store.dispatch);
// Create a Redux persistor for the store
export const persistor = persistStore(store);
export {getGlossary};

// export {useCreateUserMutation, useGetUsersQuery} from './apis/adminFormApi';
