import {useOutletContext} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import getAllProjectionRemarks from './allProjectionsRemarksApi';
import Error from '../../components/ErrorPage/Error';
import CustomTable from './Table/CustomTable';
import CircularProgress from '@mui/material/CircularProgress';
import Header from '../../components/Header/Header';
import {StyledEngineProvider} from '@mui/material/styles';

const ProjectionsRemarks = () => {
	const googleIdToken = useOutletContext(); // <-- access context value
	const [data, setData] = useState([]);
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(true);
	const [text, setText] = useState('');
	const getData = () => {
		getAllProjectionRemarks({googleIdToken}).then((res) => {
			if (res.length === 0) {
				setError(true);
				setText('something went wrong');
			} else {
				setError(false);
				setData(res);
			}
		});
	};

	useEffect(() => {
		getAllProjectionRemarks({googleIdToken}).then((res) => {
			setLoading(false);
			if (res.length === 0) {
				setError(true);
				setText('something went wrong');
			}
			setData(res);
		});
	}, [googleIdToken]);
	return (
		<StyledEngineProvider injectFirst>
			<Header />
			{error ? (
				<Error
					text={text}
					handleError={getData}
				/>
			) : loading ? (
				<CircularProgress
					style={{position: 'fixed', color: 'orange', top: '50%', right: '50%'}}
				/>
			) : (
				<CustomTable
					data={data}
					getData={getData}
				/>
			)}
		</StyledEngineProvider>
	);
};

export default ProjectionsRemarks;
